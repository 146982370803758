<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="ml-1 mb-4 mt-4">Nuevo Tercero</h3>
      </v-col>
    </v-row>
    <v-form
      ref="formTercero"
      v-model="valid"
      lazy-validation
    >
      <v-row justify="center">
        <v-col cols="12" md="4" lg="4">
          <v-select
            label="Tipo de contribuyente"
            outlined
            dense
            item-text="descripcion"
            item-value="id"
            v-model="tercero.tipo_contribuyente_id"
            :rules="[rules.required]"
            @change="changeTipoPersona"
            :items="tiposContribuyentes"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-select
            label="Tipo de documento"
            outlined
            dense
            item-text="descripcion"
            item-value="id"
            :disabled="disableTipoDocumento"
            v-model="tercero.tipo_documento_id"
            :rules="[rules.required]"
            :items="tiposDocumentos"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Número documento / DV"
            outlined
            dense
            type="number"
            @input="setDigitoVerificacion"
            :rules="[rules.required]"
            v-model.number="tercero.n_documento"
          >
            <template v-slot:append>
              <div v-if="tercero.tipo_documento_id === '31'">
                {{tercero.digito_verificacion}}
              </div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="12" lg="12" v-if="tercero.tipo_documento_id === '31'">
          <v-text-field
            label="Razón social"
            outlined
            dense
            :rules="[rules.required]"
            v-model="tercero.razon_social"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3" v-if="tercero.tipo_documento_id !== '31'">
          <v-text-field
            label="Primer nombre"
            outlined
            dense
            v-model="tercero.nombre1"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3" v-if="tercero.tipo_documento_id !== '31'">
          <v-text-field
            label="Segundo nombre"
            outlined
            dense
            v-model="tercero.nombre2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3"  v-if="tercero.tipo_documento_id !== '31'">
          <v-text-field
            label="Primer apellido"
            outlined
            dense
            v-model="tercero.apellido1"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3"  v-if="tercero.tipo_documento_id !== '31'">
          <v-text-field
            label="Segundo apellido"
            outlined
            dense
            v-model="tercero.apellido2"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="E-mail"
            outlined
            dense
            :rules="[rules.required, rules.email]"
            v-model="tercero.email"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Teléfono"
            outlined
            dense
            type="number"
            counter="10"
            v-model.number="tercero.telefono"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Celular"
            outlined
            dense
            type="number"
            counter="10"
            :rules="[rules.required]"
            v-model="tercero.celular"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8" lg="8">
          <v-text-field
            label="Dirección"
            outlined
            dense
            :rules="[rules.required]"
            v-model="tercero.direccion"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-autocomplete
            label="Municipio"
            outlined
            dense
            item-text="descripcion"
            item-value="id"
            :items="municipios"
            :rules="[rules.required]"
            v-model="tercero.municipio_id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="tercero.tipo_documento_id === '31'">
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Representante legal"
            outlined
            dense
            :rules="[rules.required]"
            v-model="tercero.representante_legal"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Actividad económica"
            outlined
            dense
            :rules="[rules.required]"
            v-model="tercero.actividad_economica"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="end" class="mb-2 mr-2">
      <v-btn
        depressed
        dense
        outlined
        color="primary"
        class="mr-2"
        @click="$router.back()"
      >Cancelar</v-btn>
      <v-btn
        v-if="tercero.id"
        depressed
        dense
        color="primary"
        @click="crearActualizar"
      >Actualizar</v-btn>
      <v-btn
        v-else
        depressed
        dense
        color="primary"
        @click="crearActualizar"
      >Crear</v-btn>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { getDV } from '@/utils/digitoVerificacion'
import { POST } from '@/boot/http'
import eventBus from '@/boot/eventBus'

const defaultTercero = () => JSON.parse(JSON.stringify({
  tipo_documento_id: '31',
  tipo_contribuyente_id: '',
  digito_verificacion: '',
  nombre1: '',
  nombre2: '',
  email: '',
  estado: true,
  celular: '',
  telefono: '',
  apellido1: '',
  apellido2: '',
  direccion: '',
  n_documento: '',
  municipio_id: '',
  razon_social: '',
  retenedor_ica: true,
  gran_contribuyente: true,
  retenedor_fuente: true,
  simplificado: true,
  representante_legal: '',
  actividad_economica: '',
  configuracion: {
    pwd_firma: '',
    file_firma: '',
    file_politicafirma: ''
  }
}))

export default {
  name: 'NuevoTercero',
  data: () => ({
    disableTipoDocumento: false,
    municipios: [],
    tiposDocumentos: [],
    tiposContribuyentes: [],
    tercero: defaultTercero(),
    valid: true,
    rules: {
      required: value => !!value || 'Requerido',
      email:  val => /.+@.+\..+/.test(val) || 'Email no válido'
    }
  }),
  mounted () {
    let tercero_id = this.$route.params.tercero_id
    if (tercero_id) {
      tercero_id = typeof tercero_id === 'string' ? parseInt(tercero_id) : tercero_id
      this.tercero = JSON.parse(JSON.stringify(this.getTerceros.find(t => t.id === tercero_id)))
    }
    this.getMuniciopios()
    this.getTiposContribuyentes()
    this.getTiposDocumentos()
  },
  methods: {
    setDigitoVerificacion (nit) {
      this.tercero.digito_verificacion = getDV(nit)
    },
    changeTipoPersona(value) {
      if (value === 'PJ') {
        this.tercero.tipo_documento_id = '31'
        this.disableTipoDocumento = true
      } else {
        this.tercero.tipo_documento_id = '13'
        this.disableTipoDocumento = false
      }
    },
    crearActualizar() {
      const valid = this.$refs.formTercero.validate()
      if (valid) {
        const p_opc = this.tercero.id ? 'UPDATE' : 'INSERT'
        if (this.tercero.tipo_documento_id !== '31') {
          const { nombre1, nombre2, apellido1, apellido2 } = this.tercero
          this.tercero.razon_social = (`${nombre1.trim()}${nombre2 ? ' ' + nombre2.trim() : ''} ${apellido1.trim()}${apellido2 ? ' ' + apellido2.trim() : ''}`).trim()
        }
        POST({
          url: 'terceros/dao',
          data: {
            p_datajson: this.tercero,
            p_opc,
            p_auditoriajson: { usuario_id: this.getUserData.id }
          }
        }).then(data => {
          if (data.data.status.status === 'ok') {
            eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
              const tercero = data.data.dato
            if (p_opc === 'INSERT') {
              this.setTercero(tercero)
              this.tercero = defaultTercero()
              this.$refs.formTercero.resetValidation()
            } else {
              this.updateTercero(tercero)
            }
          } else {
            eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
          }
        }).catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
      }
    },
    getMuniciopios() {
      POST({
        url: 'municipios/query',
        data: {
          p_datajson: {},
          p_opc: 'GET_ALL',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          this.municipios = data.data.dato
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    getTiposContribuyentes() {
      POST({
        url: 'tipocontribuyente/query',
        data: {
          p_datajson: {},
          p_opc: 'GET_ALL',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          this.tiposContribuyentes = data.data.dato
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    getTiposDocumentos() {
      POST({
        url: 'tipodocumentos/query',
        data: {
          p_datajson: {},
          p_opc: 'GET_ALL',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          this.tiposDocumentos = data.data.dato
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    ...mapMutations(['setTercero', 'updateTercero'])
  },
  computed: {
    ...mapGetters(['getTerceros', 'getUserData'])
  }
}
</script>