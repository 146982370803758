<template>
  <img :src="logo" :height="height" :width="width" alt="logo">
</template>
<script>
import logo from '@/assets/img/Icono_rojo.png'

export default {
  name: 'LogoComponent',
  data: () => ({
    logo
  }),
  props: {
    width: { type: String, default: () => '70%'},
    height: { type: String, default: () => '70%' }
  }
}
</script>