import moment from 'moment'
import 'moment/locale/es'

/* Setting the locale to Spanish. */
moment.locale('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
  monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
})

/**
 * It returns the date of the first day of the month, three days ago
 * @returns A string in the format of YYYY-MM-DD
 */
export const getFirstDayOfMonth = (/*year, month*/) => {
  var d = new Date()
  d.setDate(d.getDate() - 3)
  return d.toISOString().substr(0, 10)
}

export const getCurrentDay = (/*year, month*/) => {
  var d = new Date()
  d.setDate(d.getDate())
  return d.toISOString().substr(0, 10)
}

/**
 * It takes a date in the format of 'YYYY-MM-DD' and returns a date in the format of 'MMMM Do [de]
 * YYYY'
 * @returns A function that takes a date and returns a formatted date.
 */
export const dateNotification = date => {
  return moment(date).format('D [de] MMMM [de] YYYY [a la(s)] h:mm a')
}

export const diffDates = (date1, date2, formula) => {
  const first = new Date(date1)
  const second = new Date(date2)

  return Math.floor((second - first) / formula)
}
