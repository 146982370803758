<template>
  <v-dialog
    transition="dialog-top-transition"
    persistent
    width="70%"
    :value="value"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
        dense
      >Agregar configuración</v-toolbar>
      <v-card-text>
        <v-form
          ref="configForm2"
          v-model="valid"
          class="mt-4"
          lazy-validation
        >
          <v-row justify="center" align-content="center">
            <v-col cols="12" md="12" lg="12">
              <v-select
                :items="[
                  'string',
                  'boolean',
                  'entero'
                ]"
                :rules="[rules.required]"
                outlined
                dense
                v-model="item.typedata"
                label="Tipo dato"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="12" lg="12" v-if="item.typedata === 'boolean'">
              <v-select
                :items="[
                  true,
                  false
                ]"
                :rules="[rules.required]"
                outlined
                dense
                v-model="item.value"
                label="Valor"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="12" lg="12" v-if="item.typedata === 'string'">
              <v-text-field
                outlined
                dense
                type="text"
                :rules="[rules.required]"
                v-model="item.value"
                label="Valor"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12" v-if="item.typedata === 'entero'">
              <v-text-field
                outlined
                type="number"
                dense
                :rules="[rules.required]"
                v-model="item.value"
                label="Valor"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-text-field
                outlined
                dense
                type="text"
                :rules="[rules.required]"
                v-model="item.propiedad"
                label="Nombre de la propiedad"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          color="primary"
          @click="closeModal"
        >Cancelar</v-btn>
        <v-btn
          depressed
          color="primary"
          @click="save"
        >Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

const defaultItem = () => JSON.parse(JSON.stringify({
  valor: null,
  typedata: "string",
  propiedad: ""
}))

export default {
  name: 'ConfigModal',
  props: { value: { type: Boolean, default: false } },
  data: () => ({
    valid: true,
    item: defaultItem(),
    rules: {
      required: value => !!value || 'Requerido'
    }
  }),
  methods: {
    save () {
      const valid = this.$refs.configForm2.validate()
      if (valid) {
        const { item } = this
        this.item = defaultItem()
        this.$refs.configForm2.resetValidation()
        this.$emit('closeModal', { item, value: false })
      }
    },
    closeModal () {
      this.item = defaultItem()
      this.$refs.configForm2.resetValidation()
      this.$emit('closeModal', { value: false })
    }
  }
}
</script>