<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="ml-1 mb-4 mt-4">Configurar email</h3>
      </v-col>
    </v-row>
    <v-form
      ref="emailForm"
      v-model="valid"
      lazy-validation
    >
      <v-row justify="center" align-content="center">
        <v-col cols="12" md="2" lg="2">
          <v-checkbox
            class="shrink mt-0"
            v-model="config.envioemail"
            false-value="inactivo"
            true-value="activo"
            label="Enviar emails"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="5" lg="5">
          <v-text-field
            label="ccoMAil"
            outlined
            dense
            :rules="[rules.required, rules.email]"
            v-model="config.ccoMAil"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="5" lg="5">
          <v-text-field
            label="mailFrom"
            outlined
            dense
            :rules="[rules.required, rules.email]"
            v-model="config.mailFrom"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" class="bg-error">
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="emailenvio"
            outlined
            dense
            :rules="[rules.required, rules.email]"
            v-model="config.emailenvio"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="emailpruebas"
            outlined
            dense
            :rules="[rules.required, rules.email]"
            v-model="config.emailpruebas"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="dir_xmlmodelo"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.dir_xmlmodelo"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="pwdemailenvio"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.pwdemailenvio"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="asuntotemplate"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.asuntotemplate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="file_xmlmodelo"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.file_xmlmodelo"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" lg="12">
          <v-autocomplete
            ref="configuracion"
            v-model="config.configuracion"
            :items="config.configuracion"
            outlined
            dense
            chips
            small-chips
            label="configuracion"
            multiple
            readonly
          >
            <!-- item-text="propiedad" -->
            <template v-slot:selection="{ attrs, item, index }">
              <v-chip
                small
                v-bind="attrs"
                close
                @click:close="remove(index)"
              >
                {{ item.propiedad }}
              </v-chip>
            </template>
            <template v-slot:append>
              <v-slide-x-reverse-transition
                mode="out-in"
              >
                <v-icon
                  color="primary"
                  @click="showModal"
                >mdi-plus</v-icon>
              </v-slide-x-reverse-transition>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="end" class="mb-2 mr-2">
      <v-btn
        depressed
        dense
        outlined
        color="primary"
        class="mr-2"
        @click="$router.back()"
      >Cancelar</v-btn>
      <v-btn
        v-if="(globalConfig && globalConfig.id)"
        depressed dense color="primary"
        @click="saveUpdate"
      >Actualizar</v-btn>
      <v-btn
        v-else depressed dense color="primary"
        @click="saveUpdate"
      >Crear</v-btn>
    </v-row>
    <configModal :value="modal" @closeModal="closeModal" />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import configModal from '@/components/configModal.vue'
import eventBus from '@/boot/eventBus';
import { POST } from '@/boot/http';

const defaultEmail = () => JSON.parse(JSON.stringify({
  "ccoMAil": "",
  "mailFrom": "",
  "emailenvio": "",
  "envioemail": "",
  "emailpruebas": "",
  "configuracion": [
    {
      "valor": "smtp.gmail.com",
      "typedata": "string",
      "propiedad": "mail.smtp.host"
    },
    {
      "valor": 587,
      "typedata": "entero",
      "propiedad": "mail.smtp.port"
    },
    {
      "valor": true,
      "typedata": "boolean",
      "propiedad": "mail.smtp.auth"
    },
    {
      "valor": true,
      "typedata": "boolean",
      "propiedad": "mail.smtp.starttls.enable"
    },
    {
      "valor": "*",
      "typedata": "string",
      "propiedad": "mail.smtp.ssl.trust"
    }
  ],
  "dir_xmlmodelo": "",
  "pwdemailenvio": "",
  "asuntotemplate": "Envio documento {$infocloud.celular}",
  "file_xmlmodelo": ""
}))

export default {
  name: 'ConfigiracionEmail',
  components: { configModal },
  data: () => ({
    rules: {
      required: value => !!value || 'Requerido',
      email:  val => /.+@.+\..+/.test(val) || 'Email no válido',
    },
    valid: true,
    modal: false,
    config: defaultEmail(),
    globalConfig: null
  }),
  mounted () {
    // if (this.$route.params.ec_id) {
    //   console.log(this.getEstablecimientosComercio.find(ec => ec.id == this.$route.params.ec_id));
    // }
    const id = this.$route.params.ec_id
    if (id !== undefined || id !== null ) {
      this.getConfig(id)
    }
  },
  methods: {
    getConfig (establecimiento_id) {
      POST({
        url: 'procesosdianconfig/query',
        data: {
          p_datajson: {
            establecimiento_id
          },
          p_opc: 'GET_ID',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          // console.log(data.data);
          if (data.data.dato.id) {
            this.globalConfig = data.data.dato
            if (Object.keys(data.data.dato.emailconfig).length > 0) {
              this.config = data.data.dato.emailconfig
            }
          }
          // eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    remove (index) {
      this.config.configuracion.splice(index, 1)
      if (this.config.configuracion.length < 1) {
        this.$refs.configuracion.errorMessages.push('La configuración debe tener al menos 1 elemento')
      }
    },
    saveUpdate () {
      console.log(this.globalConfig, this.config);
      const valid = this.$refs.emailForm.validate()
      if (valid && this.config.configuracion.length > 0) {
        const p_opc = this.globalConfig ? 'UPDATE_EMAIL' : 'INSERT_EMAIL'
        POST({
          url: 'procesosdianconfig/dao',
          data: {
            p_datajson: {
              emailconfig: this.config,
              establecimientocomercio_id: this.$route.params.ec_id,
              id: this.globalConfig ? this.globalConfig.id : undefined
            },
            p_opc,
            p_auditoriajson: { usuario_id: this.getUserData.id }
          }
        }).then(data => {
          if (data.data.status.status === 'ok') {
            eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
          } else {
            eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
          }
        }).catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
      }
    },
    showModal () {
      // console.log('Modal');
      this.modal = true
    },
    closeModal ({ item, value }) {
      this.modal = value
      if (item) {
        const length = this.config.configuracion.length
        this.config.configuracion.push(item)
        if (length === 0) {
          this.$refs.configuracion.errorMessages.pop()
        }
      }
    }
  },
  computed: { ...mapGetters(['getEstablecimientosComercio', 'getUserData']) }
}
</script>