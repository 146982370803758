<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="ml-1 mb-4 mt-4">Nuevo establecimiento</h3>
      </v-col>
    </v-row>
    <v-form
      ref="establecimientoComercioForm"
      v-model="valid"
      lazy-validation
    >
      <v-row justify="center">
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Descripción"
            outlined
            dense
            :rules="[rules.required]"
            v-model="establecimientoComercio.descripcion"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Signer"
            outlined
            dense
            :rules="[rules.required]"
            v-model="establecimientoComercio.signer"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="end" class="mb-2 mr-2">
      <v-btn
        depressed
        dense
        outlined
        color="primary"
        class="mr-2"
        @click="$router.back()"
      >Cancelar</v-btn>
      <v-btn
        v-if="establecimientoComercio.id"
        depressed dense color="primary"
        @click="crearActualizar"
      >Actualizar</v-btn>
      <v-btn
        v-else depressed dense color="primary"
        @click="crearActualizar"
      >Crear</v-btn>
    </v-row>
  </v-container>
</template>
<script>
import { POST } from '@/boot/http'
import { mapGetters, mapMutations } from 'vuex'
import eventBus from '@/boot/eventBus'

const defaultEstablecimiento = () => JSON.parse(JSON.stringify({
  descripcion: '',
  signer: '',
  tercero_id: ''
}))

export default {
  name: 'NuevoEstablecimientoComercio',
  data: () => ({
    establecimientoComercio: defaultEstablecimiento(),
    valid: true,
    rules: {
      required: value => !!value || 'Requerido'
    }
  }),
  mounted () {
    let ec_id = this.$route.params.ec_id
    if (ec_id) {
      ec_id = typeof ec_id === 'string' ? parseInt(ec_id) : ec_id
      this.establecimientoComercio = JSON.parse(JSON.stringify(this.getEstablecimientosComercio.find(ec => ec.id === ec_id)))
    }
  },
  methods: {
    crearActualizar () {
      const valid = this.$refs.establecimientoComercioForm.validate()
      if (valid) {
        this.establecimientoComercio.tercero_id = this.getUltimoTercero
        const p_opc = this.establecimientoComercio.id ? 'UPDATE' : 'INSERT'
        POST({
          url: 'establecimientos_comerciales/dao',
          data: {
            p_datajson: this.establecimientoComercio,
            p_opc,
            p_auditoriajson: { usuario_id: this.getUserData.id }
          }
        }).then(data => {
          if (data.data.status.status === 'ok') {
            eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
            if (p_opc === 'INSERT') {
              this.setEstablecimientoComercio(data.data.dato)
              this.establecimientoComercio = defaultEstablecimiento()
              this.$refs.establecimientoComercioForm.resetValidation()
            } else {
              this.updateEstablecimientoComercio(data.data.dato)
            }
          } else {
            eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
          }
        }).catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
      }
    },
    ...mapMutations(['setEstablecimientoComercio', 'updateEstablecimientoComercio'])
  },
  computed: {
    ...mapGetters(['getEstablecimientosComercio', 'getUserData', 'getTerceros', 'getUltimoTercero'])
  }
}
</script>