<template>
  <v-container>
    <v-row class="mb-6" justify="end">
      <v-col cols="2">
        <v-btn
          depressed
          color="primary"
          to="/home/terceros/nuevo-tercero"
        >
          <span v-if="$vuetify.breakpoint.mdAndUp">
            Nuevo
          </span>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <h3 class="ml-1 mb-4">Terceros</h3>
        <v-data-table
          dense
          :headers="headers"
          :items="terceros"
          item-key="id"
          :loading="loadingDataTable"
          no-data-text="No hay terceros para mostrar"
          hide-default-footer
          loading-text="Cargando terceros..."
          class="elevation-0"
        >
          <template v-slot:[`item.razon_social`]="{ item }">
            <div v-if="item.tipo_documento_id == '13'">{{ item.nombre1 }} {{ item.apellido1 }}</div>
            <div v-else-if="item.tipo_documento_id == '31'">{{ item.razon_social }}</div>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ name: 'configuracion-tercero', params: { tercero_id: item.id } })"
                >mdi-cog</v-icon>
              </template>
              <span>Configuración</span>
            </v-tooltip>
            <v-icon
              color="primary"
              class="mr-2"
              @click="$router.push({ name: 'editar-tercero', params: { tercero_id: item.id } })"
            >
              mdi-account-edit
            </v-icon>
            <v-icon
              color="primary"
              @click="confirmRemove(item)"
            >mdi-delete</v-icon>
          </template>
          <template v-slot:top>
            <v-text-field
              v-model="search"
              @input="onSearch"
              label="Buscar registros"
              outlined
              dense
              class="mx-4"
            ></v-text-field>
          </template>
        </v-data-table>
        <v-pagination
          class="mt-2"
          :total-visible="7"
          v-model="pagination.page"
          :length="pagination.itemsLength"
          @input="getListTerceros($event, search)"
        ></v-pagination>
      </v-col>
    </v-row>
    <modalConfirmacion :data="dataRemove" @close-confirm="close" />
  </v-container>
</template>
<script>
import { POST } from '@/boot/http'
import { mapGetters, mapMutations } from 'vuex'
import eventBus from '@/boot/eventBus'
import modalConfirmacion from '@/components/modalConfirmacion.vue'

export default {
  name: 'TerceroIndex',
  components: {modalConfirmacion},
  data: () => ({
    dataRemove: {},
    search: '',
    loadingDataTable: false,
    terceros: [],
    headers: [
      { text: 'Nombre / Razón social', value: 'razon_social', align: 'center' },
      // { text: 'E-mail', value: 'email', align: 'center' },
      { text: 'No Documento', value: 'n_documento', align: 'center' },
      { text: 'Teléfono', value: 'telefono', align: 'center' },
      { text: 'Acciones', value: 'acciones', align: 'center' }
    ],
    pagination: { page: 1, itemsPerPage: 10, itemsLength: 0, pageCount: 1, pageStop: 0, pageStart: 1 }
  }),
  mounted () {
    this.getListTerceros(this.pagination.page, '')
  },
  methods: {
    onSearch () {
      if (this.search) {
        this.getListTerceros(1, this.search)
      } else {
        this.getListTerceros(1, '')
      }
    },
    getListTerceros (page = 1, term = '') {
      this.loadingDataTable = true
      POST({
        url: 'terceros/query',
        data: {
          p_datajson: {
            usuario_id: this.getUserData.id,
            page,
            limit: 10,
            term
          },
          p_opc: 'FILTER',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        this.loadingDataTable = false
        if (data.data.status.status === 'ok') {
          this.terceros = [...data.data.dato.content]
          this.pagination.itemsLength = data.data.dato.totalPages
        } else if (data.data.status.status === 'err') {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        } else {
          this.terceros = []
        }
      }).catch(error => {
        this.loadingDataTable = false
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    confirmRemove(tercero) {
      const name = tercero.tipo_documento_id === 31 ? tercero.razon_social : `${tercero.nombre1} ${tercero.apellido1}`
      this.dataRemove = {
        dataId: tercero.id,
        value: true,
        title: 'Estás a punto de eliminar!',
        text: `Estás seguro que deseas eliminar el tercero ${name}?`,
        color: 'error'
      }
    },
    close(data) {
      if (data.remove) {
        this.deleteTercero(data.dataId)
      }
      this.dataRemove = {}
    },
    deleteTercero(tercero_id) {
      POST({
        url: 'terceros/dao',
        data: {
          p_datajson: { tercero_id, usuario_id: this.getUserData.id },
          p_opc: 'DELETE',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
          this.removeTercero(tercero_id)
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    ...mapMutations(['removeTercero'])
  },
  computed: {
    ...mapGetters(['getTerceros', 'getUserData'])
  }
}
</script>