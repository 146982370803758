import eventBus from '@/boot/eventBus'

export default {
  setMenuDrawerStatus: (state, status) => {
    state.menuDrawerStatus = status
  },
  setNotificacionesIndividuales: (state, { notificaciones, p_opc }) => {
    const news = []
    notificaciones.forEach(n => {
      const obj = state.notificacionesIndividuales.find(obj => obj.id === n.id)
      const index = state.notificacionesIndividuales.indexOf(obj)
      if (index === -1) {
        news.push(n)
      } else {
        if (p_opc === 'UPDATE') {
          state.notificacionesIndividuales.splice(index, 1, n)
        }
      }
    })
    state.notificacionesIndividuales = [...news, ...state.notificacionesIndividuales]
  },
  setDefaultNotificaciones: (state, notificaciones) => {
    state.notificacionesIndividuales = notificaciones
  },
  setAlertas: (state, { alertas, p_opc }) => {
    const news = []
    alertas.forEach(a => {
      const obj = state.alertas.find(obj => obj.id === a.id)
      const index = state.alertas.indexOf(obj)
      eventBus.$emit('setAlertas', { alertas, p_opc })
      if (index === -1) {
        news.push(a)
      } else {
        if (p_opc === 'UPDATE' || p_opc === 'ALERTA_EXPIRATED') {
          state.alertas.splice(index, 1, a)
        } else if (p_opc === 'DELETE') {
          state.alertas.splice(index, 1)
        }
      }
    })
    state.alertas = [...news, ...state.alertas]
  },
  setDefaultAlertas: (state, alertas) => {
    state.alertas = alertas
  },
  changeEstadoNotificaciones: (state, ids) => {
    const notificaciones = state.notificacionesIndividuales
    ids.forEach(id => {
      const obj = notificaciones.find(n => n.id === id)
      const index = notificaciones.indexOf(obj)
      if (index > -1) {
        console.log(index, { ...obj, verified: true })
        notificaciones.splice(index, 1, { ...obj, verified: true })
      }
    })
    state.notificacionesIndividuales = [...notificaciones]
  }
}
