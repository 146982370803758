import eventBus from '@/boot/eventBus'

export const getIcon = tipo => {
  let icon = 'mdi-'
  if (tipo === 'info') {
    return (icon += 'information-outline')
  }
  if (tipo === 'warning') {
    return (icon += 'shield-alert-outline')
  }
  if (tipo === 'error') {
    return (icon += 'alert-circle-outline')
  }
  if (tipo === 'success') {
    return (icon += 'check-circle-outline')
  }
}

export const onUploadProgress = progressEvent => {
  const progress = parseFloat((progressEvent.loaded / progressEvent.total).toFixed(2))
  const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  eventBus.$emit('progressBar', { visible: true, progress, value: percentage })
}

export const rules = {
  required: value => !!value || 'Requerido',
  arrayLength: value => value.length > 0 || 'Selecciones al menos un item'
}

const pdfSignature = 'data:application/pdf' // 'JVBERi0'
const excelSignature = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // 'UEsDBBQABgAIAAAAIQ'
const htmlSignature = 'data:text/html' // 'PCFET0NUWVBFIGh0bWw'

export const getFileMetaData = base64 => {
  let ext = ''
  if (base64.indexOf(excelSignature) === 0) {
    ext = 'xlsx'
  } else if (base64.indexOf(pdfSignature) === 0) {
    ext = 'pdf'
  } else if (base64.indexOf(htmlSignature) === 0) {
    ext = 'html'
  }

  return { fileData: base64, ext }
}
