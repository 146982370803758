import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#626060',
        secondary: '#E8E8E9',
        accent: '#3D3838',
        error: '#b71c1c',
        blue: '#5FC2DF',
        'links-enabled': '#505050'
      }
    }
  }
}

export default new Vuetify(opts)
