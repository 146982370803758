<template>
  <v-row>
    <menuDrawer />
    <!--
    <v-col cols="3" v-if="!this.$vuetify.breakpoint.mdAndDown">
      <MainMenu />
    </v-col> -->

    <v-col>
      <v-sheet
        min-height="70vh"
        min-width="90%"
        rounded="lg"
      >
        <router-view />
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import menuDrawer from '@/components/menuDrawer.vue'

export default {
  name: 'HomeComponent',
  data: () => ({}),
  components: { menuDrawer }
}
</script>