export default {
  setToken: (state, token) => {
    state.token = token
  },
  setUserData: (state, data) => {
    state.data = data
    state.menu = data.role ? data.role.menus : []
    state.permisos = data.role ? data.role.permisos : []
    state.ultimoEstablecimiento = data.ultimoestablecimiento
    state.ultimoTercero = data.ultimotercero
  },
  setTerceros: (state, terceros) => {
    state.terceros = terceros
  },
  setEstablecimientosComercio: (state, establecimientosComercio) => {
    state.establecimientosComercio = establecimientosComercio
  },
  setEstablecimientoComercio: (state, establecimientoComercio) => {
    state.establecimientosComercio.push(establecimientoComercio)
  },
  setUltimoEstablecimiento: (state, id) => {
    state.ultimoEstablecimiento = id
  },
  updateEstablecimientoComercio: (state, establecimientoComercio) => {
    const ec_id = establecimientoComercio.id
    const establecimientosComercio = [...state.establecimientosComercio]
    const ec = establecimientosComercio.find(ec => ec.id === ec_id)
    const index = establecimientosComercio.indexOf(ec)
    establecimientosComercio.splice(index, 1, establecimientoComercio)
    state.establecimientosComercio = establecimientosComercio
  },
  removeEstablecimientoComercio: (state, ec_id) => {
    const establecimientosComercio = [...state.establecimientosComercio]
    const ec = establecimientosComercio.find(ec => ec.id === ec_id)
    const index = establecimientosComercio.indexOf(ec)
    establecimientosComercio.splice(index, 1)
    state.establecimientosComercio = establecimientosComercio
  },
  setTercero: (state, tercero) => {
    state.terceros.push(tercero)
  },
  setUltimoTercero: (state, id) => {
    state.ultimoTercero = id
  },
  updateTercero: (state, tercero) => {
    const tercero_id = tercero.id
    const terceros = [...state.terceros]
    const ter = terceros.find(t => t.id === tercero_id)
    const index = terceros.indexOf(ter)
    terceros.splice(index, 1, tercero)
    state.terceros = terceros
  },
  removeTercero: (state, tercero_id) => {
    const terceros = [...state.terceros]
    const ter = terceros.find(t => t.id === tercero_id)
    const index = terceros.indexOf(ter)
    terceros.splice(index, 1)
    state.terceros = terceros
  }
}
