<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="ml-1 mb-4 mt-4">Nuevo rol</h3>
      </v-col>
    </v-row>
    <v-form ref="rolForm" v-model="valid" lazy-validation>
      <v-row justify="center">
        <v-col cols="12" md="12" lg="12">
          <v-text-field
            label="Descripción"
            outlined
            dense
            :rules="[rules.required]"
            v-model="rol.descripcion"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="12">
          <h4 class="ml-4 mb-6">Menú</h4>
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header>Seleccione el menú</v-expansion-panel-header>
              <v-expansion-panel-content>
                <span v-for="(item, i) in getMenu" :key="i">
                  <v-checkbox
                    v-if="!item.children"
                    :key="'ml1' + i"
                    color="primary"
                    :label="item.title"
                    v-model="rol.menus"
                    :value="{ id: item.id }"
                  >
                    <!-- @change="addRemoveMenuItem($event, item)" -->
                  </v-checkbox>
                  <v-expansion-panels accordion flat v-else>
                    <v-expansion-panel>
                      <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-checkbox
                          v-for="(child, iChild) in item.children"
                          :key="'ml2' + i + iChild"
                          color="primary"
                          :label="child.title"
                          @change="addRemoveMenuItem($event, child, item)"
                        >
                          <!-- :value="{ id: child.id }" -->
                          <!-- v-model="rol.menus" -->
                        </v-checkbox>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" md="12" lg="12">
          <h4 class="ml-4 mb-6">Permisos</h4>
          <v-expansion-panels accordion class="mb-6">
            <v-expansion-panel v-for="(item, i) in getMenu" :key="i" :disabled="!rol.menus.find(o => o.id === item.id)">
              <!-- {{ rol.menus.find(o => o.id === item.id) }} -->
              <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
              <v-expansion-panel-content v-if="!item.children">
                <v-checkbox
                  v-for="(permiso, index) in getPermisos.filter(p => p.menu_id === item.id)"
                  :key="'pl1-' + index + i"
                  color="primary"
                  v-model="rol.permisos"
                  :value="{ id: permiso.id }"
                  :label="permiso.descripcion"
                >
                  <!-- @change="addRemovePermiso($event, permiso)" -->
                </v-checkbox>
              </v-expansion-panel-content>
              <v-expansion-panel-content v-else>
                <v-expansion-panels accordion flat>
                  <v-expansion-panel
                    v-for="(child, iChild) in item.children"
                    :key="iChild"
                    :disabled="!rol.menus.find(o => o.id === child.id)"
                  >
                    <v-expansion-panel-header>{{ child.title }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-checkbox
                        v-for="(permiso, index) in getPermisos.filter(p => p.menu_id === child.id)"
                        :key="'pl2-' + index + i"
                        color="primary"
                        v-model="rol.permisos"
                        :value="{ id: permiso.id }"
                        :label="permiso.descripcion"
                      >
                        <!-- @change="addRemovePermiso($event, permiso)" -->
                      </v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="end" class="mb-2 mr-2">
      <v-btn depressed dense outlined color="primary" class="mr-2" @click="$router.back()">Cancelar</v-btn>
      <v-btn v-if="rol.id" depressed dense color="primary" @click="crearActualizar">Actualizar</v-btn>
      <v-btn v-else depressed dense color="primary" @click="crearActualizar">Crear</v-btn>
    </v-row>
  </v-container>
</template>
<script>
import eventBus from '@/boot/eventBus'
// import { POST } from '@/boot/http'
import { mapGetters } from 'vuex'
import { POST } from '@/boot/http'
// import eventBus from '@/boot/eventBus'

const defaultRol = () =>
  JSON.parse(
    JSON.stringify({
      menus: [],
      permisos: [],
      descripcion: '',
      tercero_id: null
    })
  )

export default {
  name: 'NuevoRol',
  data: () => ({
    rol: defaultRol(),
    valid: true,
    rules: {
      required: value => !!value || 'Requerido'
    }
  }),
  mounted() {
    console.log(this.getPermisos.map(r => r.authority));
    const role_id = this.$route.params.role_id
    if (role_id) {
      this.getRole(role_id)
    }
  },
  methods: {
    addRemovePermiso(evt, permiso) {
      if (evt) {
        this.rol.permisos.push(permiso)
      } else {
        const obj = this.rol.permisos.find(o => o.id === permiso.id)
        const index = this.rol.permisos.indexOf(obj)
        this.rol.permisos.splice(index, 1)
      }
    },
    addRemoveMenuItem(evt, item, parent) {
      if (evt) {
        this.rol.menus.push(item)
        if (parent) {
          const obj = this.rol.menus.find(o => o.id === parent.id)
          if (!obj) {
            this.rol.menus.push(parent)
          }
        }
      } else {
        const objItem = this.rol.menus.find(o => o.id === item.id)
        const index = this.rol.menus.indexOf(objItem)
        this.rol.menus.splice(index, 1)
        if (parent) {
          const children = this.rol.menus.filter(m => m.hijo === parent.id)
          if (children.length < 1) {
            const objParent = this.rol.menus.find(o => o.id === parent.id)
            const indexParent = this.rol.menus.indexOf(objParent)
            this.rol.menus.splice(indexParent, 1)
          }
        }
      }
    },
    getRole(role_id) {
      POST({
        url: 'roles/query',
        data: {
          p_datajson: {
            role_id
          },
          p_opc: 'FINDBYID',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      })
        .then(response => {
          if (response.data.status.status === 'ok') {
            const data = response.data
            this.rol = data.dato
            this.rol.menus = this.rol.menus.map(obj => ({ id: obj.id }))
            this.rol.permisos = this.rol.permisos.map(obj => ({ id: obj.id }))
          } else {
            eventBus.$emit('modal', { value: true, title: 'Error', text: response.data.status.mensaje, color: 'error' })
          }
          // this.loadingDataTable = false
        })
        .catch(error => {
          // this.loadingDataTable = false
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
    },
    crearActualizar() {
      const valid = this.$refs.rolForm.validate()
      if (valid) {
        if (this.rol.menus.length > 0) {
          if (this.rol.permisos.length > 0) {
            this.rol.permisos = this.rol.permisos.map(obj => ({ id: obj.id }))
            this.rol.menus = this.rol.menus.map(obj => ({ id: obj.id }))
            const p_opc = this.rol.id ? 'UPDATE' : 'INSERT'
            POST({
              url: 'roles/dao',
              data: {
                p_datajson: this.rol,
                p_opc,
                p_auditoriajson: { usuario_id: this.getUserData.id }
              }
            })
              .then(data => {
                if (data.data.status.status === 'ok') {
                  eventBus.$emit('modal', {
                    value: true,
                    title: 'Exito!',
                    text: data.data.status.mensaje,
                    color: 'primary'
                  })
                  if (p_opc === 'INSERT') {
                    this.rol = defaultRol()
                    this.$refs.rolForm.reset()
                    this.$refs.rolForm.resetValidation()
                  }
                } else {
                  eventBus.$emit('modal', {
                    value: true,
                    title: 'Error',
                    text: data.data.status.mensaje,
                    color: 'error'
                  })
                }
              })
              .catch(error => {
                eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
              })
          } else {
            eventBus.$emit('modal', {
              value: true,
              title: 'Error',
              text: 'Debe asignar al menos un permiso al rol',
              color: 'error'
            })
          }
        } else {
          eventBus.$emit('modal', {
            value: true,
            title: 'Error',
            text: 'Debe asignar al menos un item del menú al rol',
            color: 'error'
          })
        }
      }
    }
    // ...mapMutations(['setEstablecimientoComercio', 'updateEstablecimientoComercio'])
  },
  computed: {
    ...mapGetters(['getEstablecimientosComercio', 'getUserData', 'getTerceros', 'getMenu', 'getPermisos'])
  }
}
</script>