<template>
  <v-dialog
    transition="dialog-top-transition"
    persistent
    width="50%"
    :value="value"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
        dense
      >Observación</v-toolbar>
      <v-card-text>
        <div class="pt-10 pl-10 pr-10 primary--text">
          <v-select
            label="Seleccione una opción"
            outlined
            dense
            item-value="id"
            item-text="descripcion"
            return-object
            :items="rechazoConceptos"
            v-model="observacion"
            rows="2"
          >
          </v-select>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          depressed
          text
          small
          @click="closeModal(null)"
        >Cancelar</v-btn>
        <v-btn
          color="primary"
          depressed
          small
          @click="closeModal(facturaId)"
        >Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'ModalComponent',
  props: {
    value: { type: Boolean, default: false }, facturaId: { type: Number, default: null },
    rechazoConceptos: { type: Array, default: () => ([]) }
  },
  data: () => ({
    observacion: ''
  }),
  methods: {
    closeModal (factura_id) {
      this.$emit(
        'observacion-modal',
        { observacion: this.observacion, value: false, factura_id }
      )
      this.observacion = ''
    }
  }
}
</script>