<template>
  <v-container>
    <v-row class="mb-6" justify="end">
      <v-col cols="2">
        <v-btn
          depressed
          color="primary"
          to="/home/usuarios/nuevo-usuario"
        >
          <span v-if="$vuetify.breakpoint.mdAndUp">
            Nuevo
          </span>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <h3 class="ml-1 mb-4">Usuarios</h3>
        <v-data-table
          dense
          :headers="headers"
          :items="usuarios"
          item-key="id"
          :loading="loadingDataTable"
          no-data-text="No hay usuarios para mostrar"
          hide-default-footer
          loading-text="Cargando usuarios..."
          class="elevation-0"
        >
          <template v-slot:[`item.nombre1`]="{ item }">
            <div >{{ item.persona.nombre1 }} {{ item.persona.apellido1 }}</div>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-icon
              color="primary"
              class="mr-2"
              :disabled="item.id === getUserData.id"
              @click="$router.push({ name: 'editar-usuario', params: { usuario: item } })"
            >
              mdi-account-edit
            </v-icon>
            <v-icon
              color="primary"
              :disabled="item.id === getUserData.id"
              @click="confirmRemove(item)"
            >mdi-delete</v-icon>
          </template>
          <template v-slot:top>
            <v-text-field
              v-model="search"
              @input="onSearch"
              label="Buscar registros"
              outlined
              dense
              class="mx-4"
            ></v-text-field>
          </template>
        </v-data-table>
        <v-pagination
          class="mt-2"
          :total-visible="7"
          v-model="pagination.page"
          :length="pagination.itemsLength"
          @input="getUsers($event, search)"
        ></v-pagination>
      </v-col>
    </v-row>
    <modalConfirmacion :data="dataRemove" @close-confirm="close" />
  </v-container>
</template>
<script>
import { POST } from '@/boot/http'
import { mapGetters } from 'vuex'
import eventBus from '@/boot/eventBus'
// import eventBus from '@/boot/eventBus'
import modalConfirmacion from '@/components/modalConfirmacion.vue'

export default {
  name: 'UsuariosIndex',
  components: {modalConfirmacion},
  data: () => ({
    dataRemove: {},
    loadingDataTable: false,
    pagination: { page: 1, limit: 10, itemsLength: 0 },
    usuarios: [],
    search: '',
    headers: [
      { text: 'Nombre', value: 'nombre1', align: 'center' },
      { text: 'E-mail', value: 'persona.email', align: 'center' },
      { text: 'No Documento', value: 'persona.n_documento', align: 'center' },
      { text: 'Acciones', value: 'acciones', align: 'center' }
    ]
  }),
  mounted () {
    this.getUsers()
  },
  methods: {
    onSearch () {
      if (this.search) {
        this.getUsers(1, this.search)
      } else {
        this.getUsers(1, '')
      }
    },
    confirmRemove(user) {
      const name = `${user.persona.nombre1} ${user.persona.apellido1}`
      this.dataRemove = {
        dataId: user.id,
        value: true,
        title: 'Estás a punto de eliminar!',
        text: `Estás seguro que deseas eliminar el usuario ${name}?`,
        color: 'error'
      }
    },
    close(data) {
      if (data.remove) {
        this.deleteUser(data.dataId)
      }
      this.dataRemove = {}
    },
    deleteUser(usuario_id) {
      POST({
        url: 'usuarios/dao',
        data: {
          p_datajson: { usuario_id },
          p_opc: 'DELETE',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
          // this.removeTercero(tercero_id)
          this.getUsers()
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    getUsers(page = 1, term = '') {
      this.loadingDataTable = true
      // console.log({
      //   url: 'usuarios/query',
      //   data: {
      //     p_datajson: {
      //       tercero_id: this.getUltimoTercero,
      //       page,
      //       term,
      //       limit: 10
      //     },
      //     p_opc: 'FILTER',
      //     p_auditoriajson: { usuario_id: this.getUserData.id }
      //   }
      // });
      POST({
        url: 'usuarios/query',
        data: {
          p_datajson: {
            tercero_id: this.getUltimoTercero,
            page,
            term,
            limit: 10
          },
          p_opc: 'FILTER',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          this.usuarios = data.data.dato.content
          this.pagination = { page: data.data.dato.number, limit: data.data.dato.limit, itemsLength: data.data.dato.totalPages }
        } else if (data.data.status.status === 'err') {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        } else {
          this.usuarios = []
        }
        this.loadingDataTable = false
      }).catch(error => {
        this.loadingDataTable = false
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    }
    // ...mapMutations(['removeTercero'])
  },
  computed: {
    ...mapGetters(['getUltimoTercero', 'getUserData'])
  }
}
</script>