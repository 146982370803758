<template>
  <v-container>
    <v-row class="ml-6 mt-6 mb-6">
      <v-col cols="12">
        <h3>Verificar facturas</h3>
      </v-col>
    </v-row>
    <v-form ref="form" class="mx-6">
      <v-card elevation="0" outlined class="pa-6 mb-8">
        <v-row>
          <v-col cols="12" md="6" lg="6">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="p_datajson.fecha_inicio"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-model="p_datajson.fecha_inicio"
                  label="Fecha inicio"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :rules="[rules.required, () => dateCheck(p_datajson.fecha_inicio, p_datajson.fecha_final)]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="p_datajson.fecha_inicio"
                @input="$refs.menu1.save(p_datajson.fecha_inicio), (menu1 = false)"
                locale="es-co"
                no-title
                scrollable
              />
            </v-menu>
          </v-col>

          <v-col cols="12" md="6" lg="6">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="p_datajson.fecha_final"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-model="p_datajson.fecha_final"
                  label="Fecha final"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :rules="[rules.required, () => dateCheck(p_datajson.fecha_inicio, p_datajson.fecha_final)]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="p_datajson.fecha_final"
                @input="$refs.menu2.save(p_datajson.fecha_final), (menu2 = false)"
                locale="es-co"
                no-title
                scrollable
              />
            </v-menu>
          </v-col>

          <v-col cols="12">
            <v-file-input
              ref="inputFile"
              label="Cargar archivo"
              outlined
              dense
              show-size
              counter
              prepend-inner-icon="mdi-paperclip"
              prepend-icon=""
              @click:clear="$refs.inputFile.errorMessages.pop()"
              @change="onLoadedData"
              :loading="loadingFile"
              :disabled="loadingFile"
            ></v-file-input>
          </v-col>
          <v-row justify="end">
            <v-col cols="2">
              <v-btn depressed color="primary" :disabled="loadingFile" :block="$vuetify.breakpoint.xs">
                Consultar
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </v-card>
    </v-form>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :items="facturas"
          item-key="id"
          :loading="loadingDataTable"
          no-data-text="No hay facturas para mostrar"
          hide-default-footer
          loading-text="Cargando facturas..."
          class="elevation-0 mx-6"
        >
          <!-- <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  @click="$router.push({ name: 'editar-establecimiento-comercio', params: { ec_id: item.id } })"
                  mdi-store-edit
                </v-icon>
              </template>
              <span>Editar establecimiento de comercio</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ name: 'configurar-establecimiento-comercio', params: { ec_id: item.id } })"
                  >mdi-cog</v-icon
                >
              </template>
              <span>Configuración</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ name: 'configurar-email', params: { ec_id: item.id } })"
                  >mdi-email-edit</v-icon
                >
              </template>
              <span>Configuración email</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on" @click="confirmDelete(item)">mdi-delete</v-icon>
              </template>
              <span>Eliminar establecimiento de comercio</span>
            </v-tooltip>
          </template> -->
          <!-- <template v-slot:top>
            <v-text-field
              v-model="search"
              @input="onSearch"
              label="Buscar registros"
              outlined
              dense
              class="mx-4"
            ></v-text-field>
          </template> -->
        </v-data-table>
        <!-- <v-pagination
          class="mt-2"
          :total-visible="7"
          v-model="pagination.page"
          :length="pagination.itemsLength"
          @input="getEstablecimientos($event, search)"
        ></v-pagination> -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { getCurrentDay } from '@/utils/date'

export default {
  name: 'VerificarFacturasIndex',
  data: () => ({
    menu1: false,
    menu2: false,
    p_datajson: {
      fecha_inicio: getCurrentDay(),
      fecha_final: getCurrentDay()
    },
    headers: [
      { text: 'Signer', value: 'signer', align: 'center' },
      { text: 'Descripción', value: 'descripcion', align: 'center' },
      { text: 'Acciones', value: 'acciones', align: 'center' }
    ],
    facturas: [],
    loadingDataTable: false,
    loadingFile: false,
    rules: {
      required: value => !!value || 'Requerido'
    }
  }),
  methods: {
    dateCheck(date1, date2) {
      var d1 = new Date(date1)
      var d2 = new Date(date2)
      return d1.getTime() <= d2.getTime() || 'La fecha inicial debe ser menor a la fecha final'
    },
    onLoadedData(evt) {
      console.log(evt)
    }
  }
}
</script>