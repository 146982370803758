<template>
  <img :src="fullLogo" :height="height" alt="fullLogo">
</template>
<script>
import fullLogo from '@/assets/img/logo_blanco.png'

export default {
  name: 'FullLogo',
  props: {
    width: { type: String, default: () => '13%' },
    height: { type: String, default: () => '80%' }
  },
  data: () => ({
    fullLogo
  })
}
</script>