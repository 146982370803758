<template>
    <v-row align="center">
        <v-col align-self="center">
            <v-card :loading="loading" outlined elevation="0" class="mx-auto my-12" max-width="450" style="text-align: center">
                <template slot="progress">
                    <v-progress-linear color="primary" height="2" indeterminate></v-progress-linear>
                </template>

                <logo width="70" height="70" />

                <h2 class="px-16 primary--text">Vamos a generar un codigo de verificación!</h2>
                <v-card-text class="px-16 description-text"
                    >Por favor ingresa a continuación tu usuario y número de identificación para poder iniciar el proceso de reestablecer tu contraseña</v-card-text
                >

                <v-card-text class="px-16">
                    <v-form ref="resetPasswordForm" lazy-validation v-model="valid">
                        <v-text-field label="Usuario" outlined dense filled type="text" :rules="[rules.required]" v-model="usuario"></v-text-field>

                        <v-text-field label="Número de identificación" outlined dense filled type="number" :rules="[rules.required]" v-model="n_identififacion"></v-text-field>
                    </v-form>
                </v-card-text>

                <v-card-actions class="px-16 pb-10">
                    <v-row>
                        <v-col cols="12">
                            <v-btn color="primary" depressed block @click="resetPassword"> Reestablecer </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <a class="text--primary" @click="$router.push('/login')">Iniciar sesión</a>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import logo from '@/components/logo.vue'
import { POST } from '@/boot/http'
import eventBus from '@/boot/eventBus'
import { mapGetters } from 'vuex'
// import { POST } from '@/boot/http'
// import eventBus from '@/boot/eventBus'

export default {
    name: 'ResetPassword',
    data: () => ({
        usuario: '',
        n_identififacion: '',
        valid: true,
        rules: {
            required: value => !!value || 'Requerido',
            min: v => v.length >= 8 || 'Minimo 8 caracteres'
        },
        loading: false
    }),
    methods: {
        resetPassword() {
            const valid = this.$refs.resetPasswordForm.validate()
            if (valid) {
                const { usuario, n_identififacion } = this
                POST({
                    url: 'recordarpwd/gencode',
                    data: {
                        p_datajson: {
                            usuario,
                            n_identififacion
                        },
                        p_opc: 'RECORDARPWD',
                        p_auditoriajson: { usuario_id: 0 }
                    }
                })
                    .then(data => {
                        // console.log(data)
                        if (data.data.status.status === 'ok') {
                            eventBus.$emit('modal', {
                                value: true,
                                title: 'Exito!',
                                text: 'Se ha generado un código de verificación, por favor revise el correo asociado al usuario ingresado',
                                color: 'primary'
                            })
                        } else {
                            eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
                        }
                    })
                    .catch(error => {
                        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
                    })
            }
        }
    },
    components: { logo },
    computed: {
        ...mapGetters(['getUserData'])
    }
}
</script>

<style scoped>
.description-text {
    line-height: 1rem;
    font-size: 0.85rem;
}
</style>
