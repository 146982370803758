<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="ml-1 mb-6 mt-2">
          <v-btn icon dense color="primary" class="mr-2" @click="$router.back()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          Mis notificaciones
        </h3>
        <v-text-field
          outlined
          dense
          color="primary"
          type="text"
          label="Buscar registros"
          class="mx-4"
          :disabled="notifications.length === 0"
          v-model="search"
          @input="onSearch"
        ></v-text-field>
        <v-virtual-scroll
          v-if="notifications.length > 0"
          height="50vh"
          item-height="100%"
          :items="notifications"
          bench="10"
        >
          <template v-slot:default="{ item, index }">
            <alert class="ma-4" :key="index" :data="{ ...item, coloredBorder: true, dismissible: false }" />
          </template>
        </v-virtual-scroll>
        <div v-else class="empty-notifications mt-12">No hay notificaciones para mostrar</div>
        <v-pagination
          v-if="notifications.length > 0"
          class="mt-2"
          :total-visible="7"
          v-model="pagination.page"
          :length="pagination.itemsLength"
          @input="getNotificaciones($event)"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import alert from '@/components/alert.vue'
import { mapGetters, mapMutations } from 'vuex'
import { POST_NOTIFICATIONS } from '@/boot/http'
import eventBus from '@/boot/eventBus'

export default {
  name: 'IndexNotificaciones',
  data: () => ({
    search: '',
    notifications: [],
    pagination: { page: 1, itemsPerPage: 10, itemsLength: 0, pageCount: 1, pageStop: 0, pageStart: 1 }
  }),
  mounted() {
    this.getNotificaciones()
    setTimeout(() => {
      this.setEstadoNotificaciones()
    }, 2000)
  },
  methods: {
    onSearch() {
      if (this.search) {
        this.getNotificaciones(1, this.search)
      } else {
        this.getNotificaciones()
      }
    },
    setEstadoNotificaciones() {
      const ids = this.getNotificacionesIndividuales.notificaciones.filter(n => !n.verified).map(n => n.id)
      if (ids.length > 0) {
        POST_NOTIFICATIONS({
          url: 'notificaciones/crud',
          data: {
            p_datajson: {
              array_id: ids,
              usuario_id: this.getUserData.id
            },
            p_opc: 'VERIFY',
            p_auditoriajson: { usuario_id: this.getUserData.id }
          }
        })
          .then(response => {
            const data = response.data
            if (data.status.status === 'ok') {
              this.changeEstadoNotificaciones(ids)
            } else {
              eventBus.$emit('modal', { value: true, title: 'Error', text: data.status.mensaje, color: 'error' })
            }
          })
          .catch(error => {
            eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
          })
      }
    },
    getNotificaciones(page = 1, term = '') {
      POST_NOTIFICATIONS({
        url: 'notificaciones/query',
        data: {
          p_datajson: {
            page,
            term,
            limit: 10,
            usuario_id: this.getUserData.id
          },
          p_opc: 'FILTER_USER',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      })
        .then(response => {
          const data = response.data
          if (data.status.status === 'ok') {
            this.notifications = data.dato.content
            this.pagination.itemsLength = data.dato.totalPages
          } else {
            eventBus.$emit('modal', { value: true, title: 'Error', text: data.status.mensaje, color: 'error' })
          }
        })
        .catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
    },
    ...mapMutations(['changeEstadoNotificaciones'])
  },
  computed: {
    ...mapGetters(['getNotificacionesIndividuales', 'getUserData'])
  },
  components: { alert }
}
</script>

<!-- height: 50vh; -->
<style>
.notifications-container {
  background-color: grey;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.empty-notifications {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>