<template>
  <v-container>
    <v-row class="mb-6" justify="end">
      <v-col cols="2">
        <v-btn
          depressed
          color="primary"
          :disabled="!getPermisos.find(p => p.authority === 'establecimientos_comerciales-crear')"
          to="/home/establecimientos-comercio/nuevo-establecimiento-comercio"
        >
          <span v-if="$vuetify.breakpoint.mdAndUp">
            Nuevo
          </span>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <h3 class="ml-1 mb-4">Establecimientos de comercio</h3>
        <v-data-table
          dense
          :headers="headers"
          :items="establecimientos"
          item-key="id"
          :loading="loadingDataTable"
          no-data-text="No hay establecimientos de comercio para mostrar"
          hide-default-footer
          loading-text="Cargando establecimientos de comercio..."
          class="elevation-0"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ name: 'editar-establecimiento-comercio', params: { ec_id: item.id } })"
                >
                  mdi-store-edit
                </v-icon>
              </template>
              <span>Editar establecimiento de comercio</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ name: 'configurar-establecimiento-comercio', params: { ec_id: item.id } })"
                >mdi-cog</v-icon>
              </template>
              <span>Configuración</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ name: 'configurar-email', params: { ec_id: item.id } })"
                >mdi-email-edit</v-icon>
              </template>
              <span>Configuración email</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="confirmDelete(item)"
                >mdi-delete</v-icon>
              </template>
              <span>Eliminar establecimiento de comercio</span>
            </v-tooltip>
          </template>
          <template v-slot:top>
            <v-text-field
              v-model="search"
              @input="onSearch"
              label="Buscar registros"
              outlined
              dense
              class="mx-4"
            ></v-text-field>
          </template>
        </v-data-table>
        <v-pagination
          class="mt-2"
          :total-visible="7"
          v-model="pagination.page"
          :length="pagination.itemsLength"
          @input="getEstablecimientos($event, search)"
        ></v-pagination>
          <!-- @input="consultarFacturas" -->
      </v-col>
    </v-row>
    <modalConfirmacion :data="dataRemove" @close-confirm="close" />
  </v-container>
</template>
<script>
import eventBus from '@/boot/eventBus'
import { mapGetters, mapMutations } from 'vuex'
import { POST } from '@/boot/http'
import modalConfirmacion from '@/components/modalConfirmacion.vue'

export default {
  name: 'TerceroIndex',
  components: { modalConfirmacion },
  data: () => ({
    search: '',
    loadingDataTable: false,
    dataRemove: {},
    establecimientos: [],
    headers: [
      { text: 'Signer', value: 'signer', align: 'center' },
      { text: 'Descripción', value: 'descripcion', align: 'center' },
      { text: 'Acciones', value: 'acciones', align: 'center' }
    ],
    pagination: { page: 1, itemsPerPage: 10, itemsLength: 0, pageCount: 1, pageStop: 0, pageStart: 1 }
  }),
  mounted () {
    this.getEstablecimientos(this.pagination.page, '')
  },
  methods: {
    onSearch () {
      if (this.search) {
        this.getEstablecimientos(1, this.search)
      } else {
        this.getEstablecimientos(1, '')
      }
    },
    getEstablecimientos (page = 1, term = '') {
      this.loadingDataTable = true
      POST({
        url: 'establecimientos_comerciales/query',
        data: {
          p_datajson: {
            tercero_id: this.getUltimoTercero,
            page,
            limit: 10,
            term
          },
          p_opc: 'FILTER',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        this.loadingDataTable = false
        if (data.data.status.status === 'ok') {
          this.establecimientos = [...data.data.dato.content]
          this.pagination.itemsLength = data.data.dato.totalPages
        } else if (data.data.status.status === 'err') {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        } else {
          this.establecimientos = []
        }
      }).catch(error => {
        this.loadingDataTable = false
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    confirmDelete (item) {
      this.dataRemove = {
        dataId: item.id,
        value: true,
        title: 'Estás a punto de eliminar!',
        text: `Estás seguro que deseas eliminar el establecimiento de comercio ${item.descripcion}?`,
        color: 'error'
      }
    },
    close(data) {
      if (data.remove) {
        this.deleteEstablecimientoComercio(data.dataId)
      }
      this.dataRemove = {}
    },
    deleteEstablecimientoComercio(establecimientoComercio_id) {
      POST({
        url: 'establecimientos_comerciales/dao',
        data: {
          p_datajson: { establecimientoComercio_id, usuario_id: this.getUserData.id },
          p_opc: 'DELETE',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
          this.removeEstablecimientoComercio(establecimientoComercio_id)
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    ...mapMutations(['removeEstablecimientoComercio'])
  },
  computed: {
    ...mapGetters(['getEstablecimientosComercio', 'getUserData', 'getUltimoTercero', 'getPermisos'])
  }
}
</script>