<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="ml-1 mb-6 mt-2">Crear Alerta</h3>
        <v-form ref="alertaForm" class="mx-6" v-model="valid" lazy-validation>
          <v-row justify="center">
            <v-col cols="12" md="5" lg="5">
              <v-text-field
                label="Titulo"
                outlined
                dense
                :rules="[rules.required]"
                v-model="alerta.encabezado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5" lg="5">
              <v-select
                label="Tipo"
                outlined
                dense
                item-text="descripcion"
                item-value="id"
                :items="tipos"
                v-model="alerta.tipo"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2" lg="2">
              <v-checkbox label="Visible" v-model="alerta.visible" color="primary" dense> </v-checkbox>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-menu
                ref="menu-date"
                v-model="menu_date"
                :close-on-content-click="false"
                :return-value.sync="alerta.expiration_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="alerta.expiration_date"
                    label="Fecha de expiración"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-close"
                    @click:append="alerta.expiration_date = ''"
                    :rules="[() => dateCheck(alerta.expiration_date)]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="alerta.expiration_date"
                  @input="$refs['menu-date'].save(alerta.expiration_date), (menu_date = false)"
                  locale="es-co"
                  no-title
                  scrollable
                />
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-menu
                ref="menu-time"
                v-model="menu_time"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time"
                    label="Hora de expiración"
                    outlined
                    dense
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    append-icon="mdi-close"
                    @click:append="time = ''"
                    readonly
                    :disabled="!alerta.expiration_date"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu_time"
                  v-model="time"
                  full-width
                  format="24hr"
                  @click:minute="$refs['menu-time'].save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-textarea
                label="Mensaje"
                outlined
                dense
                rows="3"
                :rules="[rules.required]"
                v-model="alerta.cuerpo"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row justify="end" class="mb-2 mr-2">
            <v-btn depressed dense outlined color="primary" class="mr-2" @click="$router.back()">Cancelar</v-btn>
            <v-btn v-if="alerta.id" depressed dense color="primary" @click="crearActualizarAlerta()">Actualizar</v-btn>
            <v-btn v-else depressed dense color="primary" @click="crearActualizarAlerta()">Crear</v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { rules } from '@/utils/other'
import { POST_NOTIFICATIONS } from '@/boot/http'
import { mapGetters, mapMutations } from 'vuex'
import eventBus from '@/boot/eventBus'

const alertaDefault = () =>
  JSON.parse(
    JSON.stringify({
      encabezado: 'Nueva alerta',
      cuerpo: '',
      tipo: 'info',
      visible: true,
      expiration_date: ''
    })
  )

export default {
  name: 'CrearAlertas',
  mounted() {
    // console.log(this.$route.params)
    const alerta_id = parseInt(this.$route.params.alerta_id)
    if (alerta_id) {
      this.getAlertaPorId(alerta_id)
    }
  },
  data: () => ({
    rules,
    valid: true,
    menu_date: false,
    menu_time: false,
    time: '',
    tipos: [
      { id: 'info', descripcion: 'Informativa' },
      { id: 'warning', descripcion: 'Alerta' },
      { id: 'error', descripcion: 'Error' },
      { id: 'success', descripcion: 'Satisfactoria' }
    ],
    alerta: alertaDefault()
  }),
  methods: {
    dateCheck(date) {
      if (date) {
        // console.log(date);
        const expiration_date = new Date(date)
        expiration_date.setDate(expiration_date.getDate() + 1)
        const today_date = new Date()
        today_date.setHours(0, 0, 0, 0)
        if (expiration_date < today_date) {
          return 'La fecha de expiración no puede ser inferior a la fecha actual'
        }
        return true
        // console.log(new Date(date).toISOString())
      }
      return true
    },
    getAlertaPorId(id) {
      POST_NOTIFICATIONS({
        url: 'alertas/query',
        data: {
          p_datajson: {
            id
          },
          p_opc: 'FIND_BY_ID',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      })
        .then(response => {
          const { data } = response
          if (data.status.status === 'ok') {
            // console.log(data)
            const alert = data.dato
            const [date, timeHMS] = alert.expiration_date.split('T')
            const d = new Date(date)
            const [expiration_date] = new Date(d.setDate(d.getDate())).toISOString().split('T')
            const [hours, minutes] = timeHMS.split(':')
            this.time = `${parseInt(hours)}:${minutes}`
            this.alerta = { ...alert, expiration_date }
          } else {
            eventBus.$emit('modal', {
              value: true,
              title: 'Error!',
              text: data.status.mensaje_exception || data.status.mensaje,
              color: 'error'
            })
          }
        })
        .catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
    },
    crearActualizarAlerta() {
      const valid = this.$refs.alertaForm.validate()
      const newAlert = JSON.parse(JSON.stringify(this.alerta))
      newAlert.cuerpo = newAlert.cuerpo.replace(/\n\r?/g, '<br>')
      if (newAlert.expiration_date) {
        let expiration_date = new Date(newAlert.expiration_date)
        if (this.time) {
          const [hourStr, minutesStr] = this.time.split(':')
          expiration_date.setHours(parseInt(hourStr) - 5, parseInt(minutesStr), 0)
        }
        const d = new Date(expiration_date.setDate(expiration_date.getDate() + 1))
        expiration_date = new Date(d)
        newAlert.expiration_date = expiration_date.toISOString()
      }
      console.log(newAlert);
      const p_opc = this.alerta.id ? 'UPDATE' : 'INSERT'
      if (valid) {
        POST_NOTIFICATIONS({
          url: 'alertas/crud',
          data: {
            p_datajson: newAlert,
            p_opc,
            p_auditoriajson: { usuario_id: this.getUserData.id }
          }
        })
          .then(response => {
            // console.log(response)
            const { data } = response
            if (data.status.status === 'ok') {
              eventBus.$emit('modal', {
                value: true,
                title: 'Éxito!',
                text: data.status.mensaje,
                color: 'primary'
              })
              this.alerta = alertaDefault()
              this.$refs.alertaForm.resetValidation()
              this.$router.back()
            } else {
              eventBus.$emit('modal', {
                value: true,
                title: 'Error!',
                text: data.status.mensaje_exception || data.status.mensaje,
                color: 'error'
              })
            }
          })
          .catch(error => {
            eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
          })
      }
    },
    ...mapMutations(['setAlertas'])
  },
  computed: {
    ...mapGetters(['getUserData'])
  }
}
</script>