<template>
  <v-row align="center">
    <v-col align-self="center">
      <v-card
        :loading="loading"
        outlined
        elevation="0"
        class="mx-auto my-12"
        max-width="450"
        style="text-align: center"
      >
        <template slot="progress">
          <v-progress-linear color="primary" height="2" indeterminate></v-progress-linear>
        </template>

        <logo width="30%" height="30%" />

        <h2 class="px-16 primary--text">Bienvenido!</h2>
        <v-card-text class="px-16 description-text"
          >Por favor ingresa tus credenciales a continuación y empieza a administrar tus facturas
          electrónicas</v-card-text
        >

        <v-card-text class="px-16">
          <v-form ref="loginForm" v-model="valid" lazy-validation>
            <v-text-field
              label="Usuario"
              outlined
              dense
              filled
              @keydown.enter="login()"
              :rules="[rules.required]"
              v-model="usuario"
            ></v-text-field>

            <v-text-field
              :append-icon="mostrarContrasena ? 'mdi-eye-off' : 'mdi-eye'"
              :rules="[rules.required]"
              :type="mostrarContrasena ? 'text' : 'password'"
              @keydown.enter="login()"
              name="input-10-2"
              label="Contraseña"
              hint="Al menos 8 caracteres"
              class="input-group--focused"
              outlined
              dense
              filled
              v-model="contrasena"
              @click:append="mostrarContrasena = !mostrarContrasena"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions class="px-16 pb-10">
          <v-row>
            <v-col cols="12">
              <v-btn color="primary" depressed block @click="login"> INICIAR SESIÓN </v-btn>
            </v-col>
            <v-col cols="12">
              <a class="text--primary" @click="$router.push('/reestablecer-contrasena')">Olvidé mi contraseña</a>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import logo from '@/components/logo.vue'
import { mapActions, mapMutations } from 'vuex'
import { POST } from '@/boot/http'
import eventBus from '@/boot/eventBus'

export default {
  name: 'LoginComponent',
  data: () => ({
    valid: true,
    usuario: '',
    contrasena: '',
    mostrarContrasena: false,
    rules: {
      required: value => !!value || 'Requerido',
      min: v => v.length >= 8 || 'Minimo 8 caracteres'
    },
    loading: false
  }),
  methods: {
    login() {
      const valid = this.$refs.loginForm.validate()
      if (valid) {
        this.loading = true
        POST({
          url: 'auth',
          data: {
            username: this.usuario,
            password: this.contrasena
          }
        })
          .then(data => {
            // console.log(data);
            if (data.data.status.status === 'ok') {
              localStorage.setItem('token', data.data.dato.token)
              this.setToken(data.data.dato.token)
              this.setUserData(data.data.dato.usuario)
              this.setTerceros(data.data.dato.usuario.tercero)
              this.setEstablecimientosComercio(data.data.dato.usuario.establecimientocomercio)
              this.socket_connect()
              this.$router.push('/home')
            } else {
              eventBus.$emit('modal', {
                value: true,
                title: 'Error',
                text: data.data.status.mensaje_exception || data.data.status.mensaje,
                color: 'error'
              })
            }
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            eventBus.$emit('modal', { value: true, title: 'Error', text: err.message, color: 'error' })
          })
      }
    },
    ...mapMutations(['setToken', 'setUserData', 'setTerceros', 'setEstablecimientosComercio']),
    ...mapActions(['socket_connect'])
  },
  components: { logo }
}
</script>

<style scoped>
.description-text {
  line-height: 1rem;
  font-size: 0.85rem;
}
</style>