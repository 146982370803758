export default {
  // getMenu: state => state.menu,
  getMenuDrawerStatus: state => state.menuDrawerStatus,
  getAlertas: state => state.alertas,
  getNotificacionesIndividuales: (state, getters) => {
    const { notificacionesIndividuales: notificaciones } = state
    // console.log(notificaciones)
    const count = notificaciones.filter(n => n.usuario_id === getters.getUserData.id && !n.verified).length
    return { notificaciones, count }
  }
}
