import { instance, notificationsInstance } from './config'

export function POST({ url, data, headers = {}, onUploadProgress }) {
  return instance({
    method: 'post',
    url,
    data,
    headers: { ...headers },
    onUploadProgress
  })
}

export function POST_NOTIFICATIONS({ url, data, headers = {} }) {
  return notificationsInstance({
    method: 'post',
    url,
    data,
    headers: { ...headers }
  })
}
