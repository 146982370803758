<template>
    <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="ml-1 mb-4 mt-4">Configurar tercero</h3>
      </v-col>
    </v-row>
    <v-form
      ref="formConfig"
      v-model="valid"
      lazy-validation
    >
      <v-row justify="center">
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="PWD_FIRMA"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.pwd_firma"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="FILE_FIRMA"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.file_firma"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="FILE_POLITICAFIRMA"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.file_politicafirma"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        
      </v-row>
    </v-form>
    <v-row justify="end" class="mb-2 mr-2">
      <v-btn
        depressed
        dense
        outlined
        color="primary"
        class="mr-2"
        @click="$router.back()"
      >Cancelar</v-btn>
      <v-btn
        depressed
        dense
        color="primary"
        @click="saveUpdate"
      >Guardar</v-btn>
    </v-row>
  </v-container>
</template>
<script>
import eventBus from '@/boot/eventBus'
import { mapGetters, mapMutations } from 'vuex'
import { POST } from '@/boot/http'

const defaultConfig = () => JSON.parse(JSON.stringify({
  pwd_firma: '',
  file_firma: '',
  file_politicafirma: '',
}))

export default {
  name: 'ConfiguracionTercero',
  data: () => ({
    valid: true,
    tercero: {},
    rules: {
      required: value => !!value || 'Requerido'
    },
    config: defaultConfig()
  }),
  mounted () {
    if (this.$route.params.tercero_id) {
      this.tercero = this.getTerceros.find(t => t.id == this.$route.params.tercero_id)
      this.config = this.tercero.configuracion
    }
  },
  methods: {
    saveUpdate () {
      const valid = this.$refs.formConfig.validate()
      if (valid) {
        POST({
          url: 'terceros/dao',
          data: {
            p_datajson: {
              configuracion: this.config,
              tercero_id: this.$route.params.tercero_id
            },
            p_opc: 'UPDATE_CONFIG',
            p_auditoriajson: { usuario_id: this.getUserData.id }
          }
        }).then(data => {
          if (data.data.status.status === 'ok') {
            console.log(data);
            const cnf = data.data.dato.configuracion
            this.tercero.configuracion = cnf
            this.updateTercero(this.tercero)
            eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
          } else {
            eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
          }
        }).catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
      }
    },
    ...mapMutations(['updateTercero'])
  },
  computed: { ...mapGetters(['getTerceros', 'getUserData']) },
}
</script>