import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueRouter from 'vue-router'
import store from './store'
import IdleVue from 'idle-vue'

const eventsHub = new Vue()

Vue.use(VueRouter)

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 86400000, // 24 hours
  startAtIdle: false
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
