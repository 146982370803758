const numParaCalcular = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71]

export const getDV = nit => {
  const ultimoNum = nit.length - 1
  let sumaTotal = 0

  for (let i = 0; i < nit.length; i++) {
    sumaTotal += parseInt(nit[i]) * numParaCalcular[ultimoNum - i]
  }

  const res = Math.round(((sumaTotal / 11) % 1) * 11)

  return res === 0 ? 0 : res === 1 ? 1 : 11 - res
}
