import login from '@/components/login.vue'
import home from '@/components/home.vue'
import recepcionDocumentos from '@/components/recepcionDocumentos.vue'
import terceros from '@/modules/terceros/terceros.vue'
import establecimientosComercio from '@/modules/establecimientosComercio/establecimientosComercio.vue'
import nuevoTercero from '@/modules/terceros/components/nuevoTercero.vue'
import configuracionTercero from '@/modules/terceros/components/configuracion.vue'
import indexTerceros from '@/modules/terceros/components/index.vue'
import nuevoEstablecimientoComercio from '@/modules/establecimientosComercio/components/nuevoEstablecimientoComercio.vue'
import indexEstablecimientoComercio from '@/modules/establecimientosComercio/components/index.vue'
import configuracionEstablecimientoComercio from '@/modules/establecimientosComercio/components/configuracion.vue'
import indexUsuarios from '@/modules/usuarios/components/index.vue'
import usuariosComponent from '@/modules/usuarios/usuarios.vue'
import nuevoUsuario from '@/modules/usuarios/components/nuevoUsuario.vue'
import indexRoles from '@/modules/roles/components/index.vue'
import rolesComponent from '@/modules/roles/roles.vue'
import nuevoRol from '@/modules/roles/components/nuevoRol.vue'
import configuracionEmail from '@/modules/establecimientosComercio/components/configuracionEmail.vue'
import perfil from '@/modules/perfil/perfil.vue'
import resetPassword from '@/components/resetPassword.vue'
import resetPassword2 from '@/components/resetPassword2.vue'
import verificacionFactura from '@/modules/informes/verificacionDeFacturas/verificacion.vue'
import verificacionFacturaIndex from '@/modules/informes/verificacionDeFacturas/components/index.vue'
import notificaciones from '@/modules/notificaciones/notificaciones.vue'
import indexNotificaciones from '@/modules/notificaciones/components/index.vue'
import crearNotificaciones from '@/modules/notificaciones/components/crearNotificaciones.vue'
import listaNotificaciones from '@/modules/notificaciones/components/listaNotificaciones.vue'
import alertas from '@/modules/alertas/alertas.vue'
import indexAlertas from '@/modules/alertas/componentes/index.vue'
import crearAlerta from '@/modules/alertas/componentes/crearAlerta.vue'
import comparacionDianVsSoftware from '@/modules/comparacion-dian-vs-software/index.vue'

export const menuRoutes = [
  {
    path: '/home',
    component: home,
    name: 'home',
    redirect: '/home/recepcion-documentos',
    showName: 'Home',
    meta: { requiresAuth: true },
    children: [
      {
        path: 'recepcion-documentos',
        name: 'recepcion-documentos',
        showName: 'Recepción de documentos',
        meta: { requiresAuth: true },
        component: recepcionDocumentos
      },
      {
        path: 'terceros',
        name: 'terceros',
        showName: 'Terceros',
        meta: { requiresAuth: true },
        component: terceros,
        children: [
          {
            path: '/',
            name: 'index-terceros',
            showName: 'Lista de Terceros',
            meta: { requiresAuth: true },
            component: indexTerceros
          },
          {
            path: 'nuevo-tercero',
            name: 'nuevo-tercero',
            showName: 'Nuevo tercero',
            meta: { requiresAuth: true },
            component: nuevoTercero
          },
          {
            path: 'editar-tercero/:tercero_id',
            name: 'editar-tercero',
            showName: 'Editar tercero',
            meta: { requiresAuth: true },
            component: nuevoTercero
          },
          {
            path: 'configuracion-tercero/:tercero_id',
            name: 'configuracion-tercero',
            showName: 'configuracion-tercero',
            meta: { requiresAuth: true },
            component: configuracionTercero
          }
        ]
      },
      {
        path: 'establecimientos-comercio',
        name: 'establecimientos-comercio',
        showName: 'Establecimientos de Comercio',
        meta: { requiresAuth: true },
        component: establecimientosComercio,
        children: [
          {
            path: '/',
            name: 'index-establecimiento-comerio',
            showName: 'Lista de establecimiento de comercio',
            meta: { requiresAuth: true },
            component: indexEstablecimientoComercio
          },
          {
            path: 'nuevo-establecimiento-comercio',
            name: 'nuevo-establecimiento-comercio',
            showName: 'Nuevo establecimiento de comercio',
            meta: { requiresAuth: true },
            component: nuevoEstablecimientoComercio
          },
          {
            path: 'editar-establecimiento-comercio/:ec_id',
            name: 'editar-establecimiento-comercio',
            showName: 'Editar establecimiento de comercio',
            meta: { requiresAuth: true },
            component: nuevoEstablecimientoComercio
          },
          {
            path: 'configurar-establecimiento-comercio/:ec_id',
            name: 'configurar-establecimiento-comercio',
            showName: 'Configurar establecimiento de comercio',
            meta: { requiresAuth: true },
            component: configuracionEstablecimientoComercio
          },
          {
            path: 'configurar-email/:ec_id',
            name: 'configurar-email',
            showName: 'Configurar email',
            meta: { requiresAuth: true },
            component: configuracionEmail
          }
        ]
      },
      {
        path: 'perfil',
        name: 'perfil',
        showName: 'Perfiñ',
        meta: { requiresAuth: true },
        component: perfil
      },
      {
        path: 'usuarios',
        name: 'usuarios',
        showName: 'Usuarios',
        meta: { requiresAuth: true },
        component: usuariosComponent,
        children: [
          {
            path: '/',
            name: 'index-usuarios',
            showName: 'Listado de usuarios',
            meta: { requiresAuth: true },
            component: indexUsuarios
          },
          {
            path: 'nuevo-usuario',
            name: 'nuevo-usuario',
            showName: 'Nuevo usuario',
            meta: { requiresAuth: true },
            component: nuevoUsuario
          },
          {
            path: 'editar-usuario',
            name: 'editar-usuario',
            showName: 'Editar establecimiento de comercio',
            meta: { requiresAuth: true },
            component: nuevoUsuario
          }
        ]
      },
      {
        path: 'roles',
        name: 'roles',
        showName: 'Roles',
        meta: { requiresAuth: true },
        component: rolesComponent,
        children: [
          {
            path: '/',
            name: 'index-roles',
            showName: 'Listado de roles',
            meta: { requiresAuth: true },
            component: indexRoles
          },
          {
            path: 'nuevo-rol',
            name: 'nuevo-rol',
            showName: 'Nuevo rol',
            meta: { requiresAuth: true },
            component: nuevoRol
          },
          {
            path: 'editar-rol/:role_id',
            name: 'editar-rol',
            showName: 'Editar rol',
            meta: { requiresAuth: true },
            component: nuevoRol
          }
        ]
      },
      {
        path: 'informes',
        name: 'Informes',
        component: verificacionFactura,
        meta: { requiresAuth: true },
        children: [
          {
            path: 'comparacion-facturas',
            name: 'index-comparacion-facturas',
            meta: { requiresAuth: true },
            component: verificacionFacturaIndex
          }
        ]
      },
      {
        path: 'notificaciones',
        name: 'Notificaciones',
        component: notificaciones,
        meta: { requiresAuth: true },
        children: [
          {
            path: 'mis-notificaciones',
            name: 'mis-notificaciones',
            meta: { requiresAuth: true },
            component: indexNotificaciones
          },
          {
            path: 'lista-notificaciones',
            name: 'lista-notificaciones',
            meta: { requiresAuth: true },
            component: listaNotificaciones
          },
          {
            path: 'crear-notificaciones',
            name: 'crear-notificaciones',
            meta: { requiresAuth: true },
            component: crearNotificaciones
          },
          {
            path: 'editar-notificacion/:notificacion_id',
            name: 'editar-notificacion',
            meta: { requiresAuth: true },
            component: crearNotificaciones
          }
        ]
      },
      {
        path: 'alertas',
        name: 'Alertas',
        component: alertas,
        meta: { requiresAuth: true },
        children: [
          {
            path: 'listado-alertas',
            name: 'listado-alertas',
            meta: { requiresAuth: true },
            component: indexAlertas
          },
          // {
          //   path: 'lista-notificaciones',
          //   name: 'lista-notificaciones',
          //   meta: { requiresAuth: true },
          //   component: listaNotificaciones
          // },
          {
            path: 'crear-alerta',
            name: 'crear-alerta',
            meta: { requiresAuth: true },
            component: crearAlerta
          },
          {
            path: 'editar-alerta/:alerta_id',
            name: 'editar-alerta',
            meta: { requiresAuth: true },
            component: crearAlerta
          }
        ]
      },
      {
        path: '/home/herramientas/comparacion-dian-vs-software',
        name: 'Alertas',
        component: comparacionDianVsSoftware,
        meta: { requiresAuth: true }
      }
    ]
  }
]

export default [
  {
    path: '',
    redirect: '/login',
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    component: login,
    name: 'login',
    showName: 'Login',
    meta: { requiresAuth: false }
  },
  {
    path: '/reestablecer-contrasena',
    component: resetPassword,
    name: 'reestablecer-contrasena',
    showName: 'reestablecer-contrasena',
    meta: { requiresAuth: false }
  },
  {
    path: '/reestablecer-contrasena-enviar-codigo',
    component: resetPassword2,
    name: 'reestablecer-contrasena-enviar-codigo',
    showName: 'reestablecer-contrasena-enviar-codigo',
    meta: { requiresAuth: false }
  },
  ...menuRoutes
]
