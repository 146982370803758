import axios from 'axios'
import store from '@/store'
import router from '@/router'

const env = process.env.NODE_ENV

// const env = 'dev'

// const env = 'production'

const api_url = env === 'production' ? 'https://app.softbusiness.com.co' : 'https://dev.softbusinesssr.com'
// const api_url = 'https://app.softbusiness.com.co'

const instance = axios.create({
    baseURL: `${api_url}/8866/api/oper/`,
    // baseURL: `${api_url}/8999/api/oper/`,
    // baseURL: 'https://app.softbusiness.com.co/8866/api/oper/',
    headers: {
        'Content-Type': 'application/json'
    }
})

console.log('backend-principal: ', api_url)

const notification_url = env === 'production' ? 'https://app.softbusiness.com.co/8833/' : 'https://dev.softbusinesssr.com/8833/'

const notificationsInstance = axios.create({
    baseURL: `${notification_url}api/websocket`,
    // baseURL: 'https://app.softbusinesssr.com.co/8833/api/websocket',
    headers: {
        'Content-Type': 'application/json'
    }
})

console.log('notificaciones-alertas: ', notification_url)

const requestInterceptorResponse = config => {
    const token = localStorage.getItem('token')

    if (token) {
        config.headers.common['Authorization'] = `Bearer ${token}`
    }

    return config
}

const requestInterceptorError = error => {
    return Promise.reject(error)
}

const responseInterceptorResponse = response => {
    return response
}

const responseInterceptorError = error => {
    if (error.response.status === 401) {
        localStorage.removeItem('token')
        store.commit('setToken', null)
        store.commit('setUserData', {})
        store.commit('setTerceros', [])
        // store.commit('setNotificacionesIndividuales', [])
        store.commit('setEstablecimientosComercio', [])
        router.push('/login')
    }
    return Promise.reject(error)
}

instance.interceptors.request.use(requestInterceptorResponse, requestInterceptorError)

instance.interceptors.response.use(responseInterceptorResponse, responseInterceptorError)

notificationsInstance.interceptors.request.use(requestInterceptorResponse, requestInterceptorError)

notificationsInstance.interceptors.response.use(responseInterceptorResponse, responseInterceptorError)

export { instance, notificationsInstance, notification_url }
