<template>
  <v-dialog
    transition="dialog-top-transition"
    persistent
    width="500"
    :value="data.visible"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
        dense
      >Cargando...</v-toolbar>
      <v-card-text class="pt-5 pl-10 pr-10 primary--text">
        <v-progress-linear
          indeterminate
          color="secondary"
          height="30"
          stream
          rounded
        >
        </v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ProgressBarInfinite',
  props: {
    data: { visible: { type: Boolean, default: false } }
  },
  data: () => ({
  })
}
</script>