<template>
  <!-- <v-card
    height="400"
    width="256"
    class="mx-auto"
  > -->
  <v-navigation-drawer fixed v-model="menuStatus" temporary clipped width="auto">
    <v-list-item>
      <!-- <v-list-item-avatar
          v-if="getToken"
          color="secondary"
        >
          {{ getInitials }}
        </v-list-item-avatar> -->
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ getFullName }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ getEmail }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <!-- <v-list
        dense
        nav
      >
        <v-list-item link to="/home/terceros" active-class="secondary primary--text">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Terceros</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/home/establecimientos-comercio" active-class="secondary primary--text">
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Establecimientos de comercio</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/home/recepcion-documentos" active-class="secondary primary--text">
          <v-list-item-icon>
            <v-icon>mdi-file-document-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Recepcion de documentos</v-list-item-title>
        </v-list-item>
        <v-list-group
          no-action
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Seguridad</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-hexagon-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Roles</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            to="/home/usuarios"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Usuarios</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>

        <v-list-item
          link
          @click="logout"
        >
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Cerrar sesión</v-list-item-title>
        </v-list-item>
      </v-list> -->
    <v-list dense nav>
      <span v-for="(item, index) in getMenu" :key="index">
        <v-list-item v-if="!item.hijo && !item.children" link :to="item.routing" active-class="secondary primary--text">
          <v-list-item-icon>
            <v-icon>{{ item.icono }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>

        <v-list-group v-else no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(child, indexChild) in item.children" :key="indexChild" :to="child.routing" link>
            <v-list-item-icon>
              <v-icon>{{ child.icono }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ child.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </span>
      <v-list-item link to="/home/perfil">
        <v-list-item-icon>
          <v-icon>mdi-account-circle</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Perfil</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item link @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-power</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Cerrar sesión</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <!-- </v-card> -->
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'MenuDrawer',
  data: () => ({
    items: [
      { title: 'Dashboard', icon: 'mdi-view-dashboard' },
      { title: 'Photos', icon: 'mdi-image' },
      { title: 'About', icon: 'mdi-help-box' }
    ],
    right: null
  }),
  methods: {
    logout() {
      localStorage.removeItem('token')
      this.setToken(null)
      this.setUserData({})
      this.setDefaultAlertas([])
      this.setDefaultNotificaciones([])
      this.$router.push('/login')
    },
    ...mapMutations(['setMenuDrawerStatus', 'setToken', 'setUserData', 'setDefaultAlertas', 'setDefaultNotificaciones'])
  },
  computed: {
    menuStatus: {
      get() {
        return this.getMenuDrawerStatus
      },
      set(value) {
        this.setMenuDrawerStatus(value)
      }
    },
    getFullName() {
      if (this.getUserData.persona) {
        return `${this.getUserData.persona.nombre1.toUpperCase()} ${this.getUserData.persona.apellido1.toUpperCase()}`
      } else {
        return ''
      }
    },
    getEmail() {
      if (this.getUserData.persona) {
        return this.getUserData.persona.email
      } else {
        return ''
      }
    },
    ...mapGetters(['getMenuDrawerStatus', 'getToken', 'getUserData', 'getMenu'])
  }
}
</script>