<template>
  <v-dialog
    transition="dialog-top-transition"
    persistent
    width="auto"
    :value="data.value"
  >
    <v-card>
      <v-toolbar
        :color="data.color"
        dark
        flat
        dense
      >{{ data.title }}</v-toolbar>
      <v-card-text>
        <div class="pt-10 pl-10 pr-10 primary--text">{{ data.text }}</div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          small
          @click="closeModal(false)"
        >Cancelar</v-btn>
        <v-btn
          text
          small
          @click="closeModal(true)"
        >Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// import eventBus from '@/boot/eventBus'

export default {
  name: 'ModalComponent',
  props: { data: { type: Object, default: () => {} } },
  data: () => ({}),
  methods: {
    closeModal (value) {
      this.$emit('close-confirm', { dataId: this.data.dataId, value: false, remove: value })
    }
  }
}
</script>