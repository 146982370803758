import SockJS from 'sockjs-client'
import { Stomp } from '@stomp/stompjs'
import { notification_url } from '@/boot/http/config'

export default {
  socket_connect: ({ state, dispatch }) => {
    var socket = new SockJS(`${notification_url}connect`)
    state.stompClient = Stomp.over(socket)
    state.stompClient.connect({}, function (/*frame*/) {
      dispatch('subscribe_alerts')
      dispatch('subscribe_notifications')
    })
  },
  subscribe_alerts: ({ state, commit }) => {
    state.stompClient.subscribe('/alertador/alertas', data => {
      const request_data = JSON.parse(data.body)
      const { p_datajson, p_opc } = request_data
      commit('setAlertas', { alertas: [p_datajson], p_opc })
    })
  },
  subscribe_notifications: ({ state, getters, commit }) => {
    const { id } = getters.getUserData
    state.stompClient.subscribe(`/alertador/notificaciones/${id}`, data => {
      const request_data = JSON.parse(data.body)
      const { p_datajson, p_opc } = request_data
      const notification = p_datajson
      const index = (notification.usuarios_id || notification.usuarios.map(u => u.usuario_id)).indexOf(id)
      if (index > -1) {
        commit('setNotificacionesIndividuales', { notificaciones: [{ ...notification, usuario_id: id }], p_opc })
      }
    })
  }
}
