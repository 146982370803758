<template>
  <v-dialog
    transition="dialog-top-transition"
    persistent
    max-width="70%"
    :value="value"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
        dense
      >Cambiar contraseña</v-toolbar>
      <v-card-text>
        <v-form
          ref="passwordForm"
          v-model="valid"
          class="mt-4"
          lazy-validation
        >
          <v-row justify="center" align-content="center">
            <v-col cols="12" md="12" lg="12">
              <v-text-field
                :rules="[rules.required]"
                outlined
                dense
                v-model="password.actual"
                label="Contraseña actual"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-text-field
                :rules="[rules.required]"
                outlined
                dense
                v-model="password.nueva"
                label="Nueva contraseña"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-text-field
                :rules="[rules.required, () => password.nueva === password.confirmacion || 'Las contraseñas no coinsiden']"
                outlined
                dense
                :disabled="(password.nueva === '') && (password.nueva.length < 8)"
                v-model="password.confirmacion"
                label="Confirmar contraseña"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          color="primary"
          @click="closeModal"
        >Cancelar</v-btn>
        <v-btn
          depressed
          color="primary"
          @click="save"
        >Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

const defaultPassword = () => JSON.parse(JSON.stringify({
  actual: "",
  nueva: "",
  confirmacion: ""
}))

export default {
  name: 'ConfigModal',
  props: { value: { type: Boolean, default: false } },
  data: () => ({
    valid: true,
    password: defaultPassword(),
    rules: {
      required: value => !!value || 'Requerido'
    }
  }),
  methods: {
    save () {
      const valid = this.$refs.passwordForm.validate()
      if (valid) {
        const { password } = this
        this.password = defaultPassword()
        this.$refs.passwordForm.resetValidation()
        this.$emit('closeChangePassword', { value: false, data: password })
      }
    },
    closeModal () {
      this.password = defaultPassword()
      this.$refs.passwordForm.resetValidation()
      this.$emit('closeChangePassword', { value: false })
    }
  }
}
</script>