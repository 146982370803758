<template>
  <v-alert
    border="right"
    :dark="data.dark"
    :colored-border="data.coloredBorder"
    :color="data.tipo"
    elevation="1"
    dense
    :dismissible="data.dismissible"
  >
    <span class="header-notificacion">
      <h3 class="text-h5" v-if="data.encabezado">{{ data.encabezado }}</h3>
      <!-- <v-badge dot color="red" class="mr-2" v-if="!data.verified"></v-badge> -->
    </span>
    <p v-html="data.cuerpo"></p>
  </v-alert>
</template>
<script>
export default {
  name: 'AlertComponent',
  props: {
    data: {
      default: () => ({
        dark: true,
        dismissible: true,
        coloredBorder: false,
        cuerpo: 'Fusce commodo aliquam arcu. Pellentesque posuere. Phasellus tempus. Donec posuere vulputate arcu.',
        encabezado: 'Alert',
        tipo: 'info'
      }),
      type: Object
    }
  },
  data: () => ({})
}
</script>
<style>
.header-notificacion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>