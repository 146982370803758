<template>
    <v-container>
        <v-row class="mb-6" justify="end">
            <v-col cols="2">
                <v-btn depressed color="primary" to="/home/alertas/crear-alerta">
                    <span v-if="$vuetify.breakpoint.mdAndUp"> Nuevo </span>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <h3 class="ml-1 mb-4">
                    <v-btn icon dense color="primary" class="mr-2" @click="$router.back()">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    Alertas
                </h3>
                <v-data-table
                    dense
                    :headers="headers"
                    :items="alertas"
                    item-key="id"
                    :loading="loadingDataTable"
                    no-data-text="No hay alertas para mostrar"
                    loading-text="Cargando alertas..."
                    class="elevation-0"
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-text-field v-model="search" @input="onSearch" label="Buscar registros" outlined dense class="mx-4"></v-text-field>
                    </template>
                    <template v-slot:[`item.fecha`]="{ item }">
                        <div>{{ dateNotification(item.expiration_date) }}</div>
                    </template>
                    <template v-slot:[`item.tipo`]="{ item }">
                        <v-icon :color="item.tipo">{{ getIcon(item.tipo) }}</v-icon>
                    </template>
                    <template v-slot:[`item.visible`]="{ item }">
                        <v-icon :color="`${item.visible ? 'success' : 'primary'}`"> mdi-{{ item.visible ? 'check-circle' : 'checkbox-blank-circle-outline' }} </v-icon>
                    </template>
                    <template v-slot:[`item.acciones`]="{ item }">
                        <v-icon color="primary" class="mr-2" @click="$router.push({ name: 'editar-alerta', params: { alerta_id: item.id } })"> mdi-account-edit </v-icon>
                        <v-icon color="primary" @click="confirmRemove(item)"> mdi-delete </v-icon>
                    </template>
                </v-data-table>
                <v-pagination class="mt-2" :total-visible="7" v-model="pagination.page" :length="pagination.itemsLength"></v-pagination>
                <!-- @input="getNotificaciones($event, search)" -->
            </v-col>
        </v-row>
        <modalConfirmacion :data="dataRemove" @close-confirm="close" />
    </v-container>
</template>
<script>
import { POST_NOTIFICATIONS } from '@/boot/http'
import eventBus from '@/boot/eventBus'
import { mapGetters, mapMutations } from 'vuex'
import { dateNotification } from '@/utils/date'
import { getIcon } from '@/utils/other'
import modalConfirmacion from '@/components/modalConfirmacion.vue'

export default {
    name: 'ListadoAlertas',
    data: () => ({
        dateNotification,
        dataRemove: {},
        search: '',
        headers: [
            { text: 'Título', value: 'encabezado', align: 'center' },
            { text: 'Visible', value: 'visible', align: 'center' },
            { text: 'Tipo', value: 'tipo', align: 'center' },
            { text: 'Fecha', value: 'fecha', align: 'center' },
            { text: 'Acciones', value: 'acciones', align: 'center' }
        ],
        loadingDataTable: false,
        alertas: [],
        pagination: { page: 1, itemsPerPage: 10, itemsLength: 0, pageCount: 1, pageStop: 0, pageStart: 1 }
    }),
    mounted() {
        this.getAlertas()
        eventBus.$on('setAlertas', data => {
            this.updateAlertas(data)
        })
    },
    methods: {
        getIcon,
        onSearch() {
            if (this.search) {
                this.getAlertas(1, this.search)
            } else {
                this.getAlertas(1, '')
            }
        },
        updateAlertas({ alertas, p_opc }) {
            alertas.forEach(alerta => {
                const obj = this.alertas.find(a => a.id === alerta.id)
                const index = this.alertas.indexOf(obj)
                if (index !== -1) {
                    if (p_opc === 'ALERTA_EXPIRATED') {
                        this.alertas.splice(index, 1, alerta)
                    }
                }
            })
        },
        getAlertas(page = 1, term = '') {
            this.loadingDataTable = true
            POST_NOTIFICATIONS({
                url: 'alertas/query',
                data: {
                    p_datajson: {
                        page,
                        limit: 10,
                        term
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: { usuario_id: this.getUserData.id }
                }
            })
                .then(response => {
                    const { data } = response
                    //   console.log(data)
                    if (data.status.status === 'ok') {
                        this.alertas = data.dato.content
                        this.pagination.itemsLength = data.dato.totalPages
                    } else {
                        eventBus.$emit('modal', { value: true, title: 'Error', text: data.status.mensaje, color: 'error' })
                    }
                    this.loadingDataTable = false
                })
                .catch(error => {
                    eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
                    this.loadingDataTable = false
                })
        },
        confirmRemove(alerta) {
            this.dataRemove = {
                dataId: alerta.id,
                value: true,
                title: 'Estás a punto de eliminar!',
                text: `Estás seguro que deseas eliminar la alerta ${alerta.encabezado}?`,
                color: 'error'
            }
        },
        close(data) {
            if (data.remove) {
                this.deleteAlerta(data.dataId)
            }
            this.dataRemove = {}
        },
        deleteAlerta(id) {
            POST_NOTIFICATIONS({
                url: 'alertas/crud',
                data: {
                    p_datajson: { id },
                    p_opc: 'DELETE',
                    p_auditoriajson: { usuario_id: this.getUserData.id }
                }
            })
                .then(data => {
                    if (data.data.status.status === 'ok') {
                        eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
                        this.setAlertas({ alertas: [{ id }], p_opc: 'DELETE' })
                        this.getAlertas()
                    } else {
                        eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
                    }
                })
                .catch(error => {
                    eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
                })
        },
        ...mapMutations(['setAlertas'])
    },
    computed: {
        ...mapGetters(['getUserData'])
    },
    components: { modalConfirmacion }
}
</script>
