<template>
  <v-dialog
    :value="dialog"
    persistent
    min-width="500"
    max-width="800"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Factura No. {{ factura.datadocumento ? factura.datadocumento.numero_documento : '' }}
      </v-card-title>

      <v-card-text>
        <eventosElectronicos :factura="factura" />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="closeDialog"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import eventosElectronicos from './eventosElectronicos'

export default {
  name: 'DetalleFactura',
  props: { dialog: { type: Boolean, default: () => false }, factura: { type: Object, default: () => ({}) } },
  data: () => ({}),
  methods: {
    closeDialog () {
      this.$emit('closeDialog', false)
    }
  },
  components: { eventosElectronicos }
}
</script>