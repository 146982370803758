<template>
  <v-app-bar app dense color="primary" flat>
    <v-container class="py-0 fill-height">
      <fullLogo />
      <!-- <v-responsive max-width="200">
        <v-text-field
          dense
          flat
          rounded
          hide-details
          class="bg secondary"
        ></v-text-field>
      </v-responsive> -->

      <v-spacer></v-spacer>

      <v-avatar v-if="getToken && this.$vuetify.breakpoint.mdAndUp" color="secondary" size="30" class="mr-4">
        <span class="primary--text">{{ getInitials }}</span>
      </v-avatar>

      <v-btn v-if="getToken && this.$vuetify.breakpoint.mdAndUp" icon to="/home/notificaciones/mis-notificaciones">
        <v-badge
          color="red"
          v-if="getNotificacionesIndividuales.count > 0"
          :content="getNotificacionesIndividuales.count"
          overlap
        >
          <v-icon color="secondary">mdi-bell</v-icon>
        </v-badge>
        <v-icon color="secondary" v-else>mdi-bell</v-icon>
      </v-btn>

      <!-- <v-menu
        offset-y
        origin="center center"
        transition="scale-transition"
        v-if="getToken && this.$vuetify.breakpoint.mdAndUp"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="mr-4" v-bind="attrs" v-on="on">
            <v-badge color="red" :content="getNotificacionesPersonales.count" overlap>
              <v-icon color="secondary">mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-for="(item, index) in getNotificacionesPersonales.notificaciones" :key="index">
            <v-list-item link to="/home/notificaciones/mis-notificaciones">
              <v-list-item-icon>
                <v-badge color="red" dot overlap v-if="!item.visto">
                  <v-icon :color="item.tipo">{{ getIcon(item.tipo) }}</v-icon>
                </v-badge>
                <v-icon :color="item.tipo" v-else>{{ getIcon(item.tipo) }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.titulo }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu> -->

      <v-btn icon @click.stop="menuStatus(!getMenuDrawerStatus)" v-if="getToken">
        <v-icon color="secondary">mdi-menu</v-icon>
      </v-btn>
    </v-container>
  </v-app-bar>
</template>
<script>
import fullLogo from '@/components/fullLogo.vue'
import { mapGetters, mapMutations } from 'vuex'
import { getIcon } from '@/utils/other'

export default {
  name: 'AppBar',
  mounted() {
    // this.links = [...this.getMenu]
  },
  data: () => ({
    links: []
  }),
  methods: {
    getIcon,
    menuStatus(value) {
      this.setMenuDrawerStatus(value)
    },
    ...mapMutations(['setMenuDrawerStatus'])
  },
  computed: {
    getInitials() {
      if (this.getUserData.persona) {
        return (
          this.getUserData.persona.nombre1.charAt(0).toUpperCase() +
          this.getUserData.persona.apellido1.charAt(0).toUpperCase()
        )
      } else {
        return ''
      }
    },
    ...mapGetters(['getToken', 'getMenuDrawerStatus', 'getUserData', 'getNotificacionesIndividuales'])
  },
  components: { fullLogo }
}
</script>