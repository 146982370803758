<template>
  <v-container>
    <v-row class="mb-6" justify="end">
      <v-col cols="2">
        <v-btn depressed color="primary" to="/home/notificaciones/crear-notificaciones">
          <span v-if="$vuetify.breakpoint.mdAndUp"> Nuevo </span>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <h3 class="ml-1 mb-4">
          <v-btn icon dense color="primary" class="mr-2" @click="$router.back()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          Notificaciones
        </h3>
        <v-data-table
          dense
          :headers="headers"
          :items="notificaciones"
          item-key="id"
          :loading="loadingDataTable"
          no-data-text="No hay notificaciones para mostrar"
          loading-text="Cargando notificaciones..."
          class="elevation-0"
          hide-default-footer
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              @input="onSearch"
              label="Buscar registros"
              outlined
              dense
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:[`item.fecha`]="{ item }">
            <div>{{ dateNotification(item.fecha) }}</div>
          </template>
          <template v-slot:[`item.tipo`]="{ item }">
            <v-icon :color="item.tipo">{{ getIcon(item.tipo) }}</v-icon>
          </template>
          <template v-slot:[`item.visible`]="{ item }">
            <v-icon :color="`${item.visible ? 'success' : 'primary'}`">
              mdi-{{ item.visible ? 'check-circle' : 'checkbox-blank-circle-outline' }}
            </v-icon>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-icon
              color="primary"
              class="mr-2"
              @click="$router.push({ name: 'editar-notificacion', params: { notificacion_id: item.id } })"
            >
              mdi-account-edit
            </v-icon>
            <v-icon color="primary" @click="confirmRemove(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
        <v-pagination
          class="mt-2"
          :total-visible="7"
          v-model="pagination.page"
          :length="pagination.itemsLength"
          @input="getNotificaciones($event, search)"
        ></v-pagination>
      </v-col>
    </v-row>
    <modalConfirmacion :data="dataRemove" @close-confirm="close" />
  </v-container>
</template>
<script>
import { POST_NOTIFICATIONS } from '@/boot/http'
import eventBus from '@/boot/eventBus'
import { mapGetters } from 'vuex'
import { dateNotification } from '@/utils/date'
import { getIcon } from '@/utils/other'
import modalConfirmacion from '@/components/modalConfirmacion.vue'

export default {
  name: 'ListaNotificaciones',
  data: () => ({
    dateNotification,
    dataRemove: {},
    search: '',
    headers: [
      { text: 'Título', value: 'encabezado', align: 'center' },
      { text: 'Visible', value: 'visible', align: 'center' },
      { text: 'Tipo', value: 'tipo', align: 'center' },
      { text: 'Fecha', value: 'fecha', align: 'center' },
      { text: 'Acciones', value: 'acciones', align: 'center' }
    ],
    loadingDataTable: false,
    notificaciones: [],
    pagination: { page: 1, itemsPerPage: 10, itemsLength: 0, pageCount: 1, pageStop: 0, pageStart: 1 }
  }),
  mounted() {
    this.getNotificaciones()
  },
  methods: {
    getIcon,
    onSearch() {
      if (this.search) {
        this.getNotificaciones(1, this.search)
      } else {
        this.getNotificaciones(1, '')
      }
    },
    getNotificaciones(page = 1, term = '') {
      this.loadingDataTable = true
      POST_NOTIFICATIONS({
        url: 'notificaciones/query',
        data: {
          p_datajson: {
            page,
            limit: 10,
            term
          },
          p_opc: 'FILTER',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      })
        .then(response => {
          const { data } = response
          // console.log(data)
          if (data.status.status === 'ok') {
            this.notificaciones = data.dato.content
            this.pagination.itemsLength = data.dato.totalPages
          } else {
            eventBus.$emit('modal', { value: true, title: 'Error', text: data.status.mensaje, color: 'error' })
          }
          this.loadingDataTable = false
        })
        .catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
          this.loadingDataTable = false
        })
    },
    confirmRemove(notificacion) {
      this.dataRemove = {
        dataId: notificacion.id,
        value: true,
        title: 'Estás a punto de eliminar!',
        text: `Estás seguro que deseas eliminar la notificación ${notificacion.encabezado}?`,
        color: 'error'
      }
    },
    close(data) {
      if (data.remove) {
        this.deleteNotificacion(data.dataId)
      }
      this.dataRemove = {}
    },
    deleteNotificacion(id) {
      const n = this.notificaciones.find(n => n.id === id)
      POST_NOTIFICATIONS({
        url: 'notificaciones/crud',
        data: {
          p_datajson: { id, ...n },
          p_opc: 'DELETE',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      })
        .then(data => {
          if (data.data.status.status === 'ok') {
            eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
            // this.removeTercero(tercero_id)
            this.getNotificaciones()
          } else {
            eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
          }
        })
        .catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
    }
  },
  computed: {
    ...mapGetters(['getUserData'])
  },
  components: { modalConfirmacion }
}
</script>