var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-top-transition","persistent":"","width":"70%","value":_vm.value}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":"","dense":""}},[_vm._v("Agregar configuración")]),_c('v-card-text',[_c('v-form',{ref:"configForm2",staticClass:"mt-4",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center","align-content":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-select',{attrs:{"items":[
                'string',
                'boolean',
                'entero'
              ],"rules":[_vm.rules.required],"outlined":"","dense":"","label":"Tipo dato"},model:{value:(_vm.item.typedata),callback:function ($$v) {_vm.$set(_vm.item, "typedata", $$v)},expression:"item.typedata"}})],1),(_vm.item.typedata === 'boolean')?_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-select',{attrs:{"items":[
                true,
                false
              ],"rules":[_vm.rules.required],"outlined":"","dense":"","label":"Valor"},model:{value:(_vm.item.value),callback:function ($$v) {_vm.$set(_vm.item, "value", $$v)},expression:"item.value"}})],1):_vm._e(),(_vm.item.typedata === 'string')?_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"text","rules":[_vm.rules.required],"label":"Valor"},model:{value:(_vm.item.value),callback:function ($$v) {_vm.$set(_vm.item, "value", $$v)},expression:"item.value"}})],1):_vm._e(),(_vm.item.typedata === 'entero')?_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"outlined":"","type":"number","dense":"","rules":[_vm.rules.required],"label":"Valor"},model:{value:(_vm.item.value),callback:function ($$v) {_vm.$set(_vm.item, "value", $$v)},expression:"item.value"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"text","rules":[_vm.rules.required],"label":"Nombre de la propiedad"},model:{value:(_vm.item.propiedad),callback:function ($$v) {_vm.$set(_vm.item, "propiedad", $$v)},expression:"item.propiedad"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.closeModal}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }