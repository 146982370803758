<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="ml-1 mb-6 mt-2">Mis notificaciones</h3>
        <v-form ref="notificacionForm" class="mx-6" v-model="valid" lazy-validation>
          <v-row justify="center">
            <v-col cols="12" md="6" lg="6">
              <v-text-field
                label="Titulo"
                outlined
                dense
                :rules="[rules.required]"
                v-model="notificacion.encabezado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-select
                label="Tipo"
                outlined
                dense
                item-text="descripcion"
                item-value="id"
                :items="tipos"
                v-model="notificacion.tipo"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-textarea
                label="Mensaje"
                outlined
                dense
                rows="3"
                :rules="[rules.required]"
                v-model="notificacion.cuerpo"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-autocomplete
                label="Destinatarios"
                outlined
                dense
                multiple
                item-text="fullName"
                item-value="id"
                :items="usuarios"
                :rules="[rules.arrayLength]"
                v-model="notificacion.usuarios_id"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    v-if="!todosDestinatarios"
                    @click="
                      ;(todosDestinatarios = !todosDestinatarios), (notificacion.usuarios_id = usuarios.map(u => u.id))
                    "
                  >
                    <v-icon color="primary">mdi-check-all</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    v-else
                    @click=";(todosDestinatarios = !todosDestinatarios), (notificacion.usuarios_id = [])"
                  >
                    <v-icon color="success">mdi-check-all</v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="end" class="mb-2 mr-2">
            <v-btn depressed dense outlined color="primary" class="mr-2" @click="$router.back()">Cancelar</v-btn>
            <v-btn v-if="notificacion.id" depressed dense color="primary" @click="crearActualizarNotificacion()"
              >Actualizar</v-btn
            >
            <v-btn v-else depressed dense color="primary" @click="crearActualizarNotificacion()">Crear</v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { rules } from '@/utils/other'
import { POST, POST_NOTIFICATIONS } from '@/boot/http'
import { mapGetters, mapMutations } from 'vuex'
import eventBus from '@/boot/eventBus'

const notificationDefault = () =>
  JSON.parse(
    JSON.stringify({
      encabezado: 'Nueva notificación',
      cuerpo: '',
      tipo: 'info',
      usuarios_id: []
    })
  )

export default {
  name: 'CrearNotificaciones',
  mounted() {
    // console.log(this.$route.params)
    const notificacion_id = parseInt(this.$route.params.notificacion_id)
    if (notificacion_id) {
      this.getNotificacionPorId(notificacion_id)
    }
    this.getUsuarios()
  },
  data: () => ({
    rules,
    valid: true,
    usuarios: [],
    todosDestinatarios: false,
    tipos: [
      { id: 'info', descripcion: 'Informativa' },
      { id: 'warning', descripcion: 'Alerta' },
      { id: 'error', descripcion: 'Error' },
      { id: 'success', descripcion: 'Satisfactoria' }
    ],
    notificacion: notificationDefault()
  }),
  methods: {
    getNotificacionPorId(id) {
      POST_NOTIFICATIONS({
        url: 'notificaciones/query',
        data: {
          p_datajson: {
            id
          },
          p_opc: 'FIND_BY_ID',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      })
        .then(response => {
          const { data } = response
          if (data.status.status === 'ok') {
            const notificacion = data.dato
            notificacion.usuarios_id = notificacion.usuarios.map(u => u.usuario_id)
            delete notificacion.usuarios
            delete notificacion.visible
            this.notificacion = notificacion
          } else {
            eventBus.$emit('modal', {
              value: true,
              title: 'Error!',
              text: data.status.mensaje_exception || data.status.mensaje,
              color: 'error'
            })
          }
        })
        .catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
    },
    crearActualizarNotificacion() {
      const p_opc = this.notificacion.id ? 'UPDATE' : 'INSERT'
      POST_NOTIFICATIONS({
        url: 'notificaciones/crud',
        data: {
          p_datajson: this.notificacion,
          p_opc,
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      })
        .then(response => {
          // console.log(response)
          const { data } = response
          if (data.status.status === 'ok') {
            eventBus.$emit('modal', {
              value: true,
              title: 'Éxito!',
              text: data.status.mensaje,
              color: 'primary'
            })
            // const notification = data.dato
            // const index = notification.usuarios_id.indexOf(this.getUserData.id)
            // if (index > -1) {
            //   this.setNotificacionesIndividuales({
            //     notificaciones: [{ ...notification, usuario_id: this.getUserData.id }],
            //     p_opc
            //   })
            // }
            this.notificacion = notificationDefault()
            this.$refs.notificacionForm.resetValidation()
            this.$router.back()
          } else {
            eventBus.$emit('modal', {
              value: true,
              title: 'Error!',
              text: data.status.mensaje_exception || data.status.mensaje,
              color: 'error'
            })
          }
        })
        .catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
    },
    getUsuarios() {
      POST({
        url: 'usuarios/query',
        data: {
          p_datajson: {},
          p_opc: 'GET_ALL',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      })
        .then(response => {
          const { data } = response
          if (data.status.status === 'ok') {
            this.usuarios = data.dato.map(u => ({ id: u.id, fullName: `${u.nombre1} ${u.apellido1}` }))
          } else {
            eventBus.$emit('modal', {
              value: true,
              title: 'Error',
              text: data.status.mensaje_exception || data.status.mensaje,
              color: 'error'
            })
          }
        })
        .catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
    },
    ...mapMutations(['setNotificacionesIndividuales'])
  },
  computed: {
    ...mapGetters(['getUserData'])
  }
}
</script>