<template>
    <v-stepper v-model="e1" outlined class="mt-5">
        <v-stepper-header>
            <v-stepper-step editable step="1"> Recibido factura </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable step="2"> Recibo del bien o servicio </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable step="3"> Aceptación expresa </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable step="4"> Reclamo Factura </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1" style="height: 300px; overflow-y: scroll">
                <h3 class="mb-4">
                    Estado:
                    <strong :class="`${factura.accion_recivido_json.IsValid === 'false' ? 'error' : 'primary'}--text`">
                        {{ factura.accion_recivido_desc }}
                    </strong>
                </h3>
                <p class="mb-4" v-if="factura.accion_recivido_date">
                    <strong class="primary--text"> Enviado y notificado: </strong>
                    {{ factura.accion_recivido_date }}
                </p>
                <p class="mb-4">{{ factura.accion_recivido_json.StatusDescription }}</p>
                <v-expansion-panels accordion>
                    <v-expansion-panel v-for="(err, i) in factura.accion_recivido_json.errores" :key="i">
                        <v-expansion-panel-header disable-icon-rotate>
                            {{ err.split(',')[0] }}
                            <template v-slot:actions>
                                <v-icon color="error"> mdi-alert-circle </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{ err.split(',')[1] }}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-btn v-if="factura.accion_recivido === 'AUTORIZADO'" color="primary" depressed class="mt-4" @click="renviarCorreo('030')"> Enviar copia email </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2" style="height: 300px; overflow-y: scroll">
                <h3 class="mb-4">
                    Estado:
                    <strong :class="`${factura.accion_recivido_bien_servicio_json.IsValid === 'false' ? 'error' : 'primary'}--text`">
                        {{ factura.accion_recivido_bien_servicio_reclamo_desc }}
                    </strong>
                </h3>

                <p class="mb-4" v-if="factura.accion_recivido_bien_servicio_date">
                    <strong class="primary--text"> Enviado y notificado: </strong>
                    {{ factura.accion_recivido_bien_servicio_date }}
                </p>

                <p class="mb-4">{{ factura.accion_recivido_bien_servicio_json.StatusDescription }}</p>

                <v-expansion-panels accordion>
                    <v-expansion-panel v-for="(err, i) in factura.accion_recivido_bien_servicio.errores" :key="i">
                        <v-expansion-panel-header disable-icon-rotate>
                            {{ err.split(',')[0] }}
                            <template v-slot:actions>
                                <v-icon color="error"> mdi-alert-circle </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{ err.split(',')[1] }}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-btn v-if="factura.accion_recivido_bien_servicio === 'AUTORIZADO'" color="primary" depressed class="mt-4" @click="renviarCorreo('032')">
                    Enviar copia email
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3" style="height: 300px; overflow-y: scroll">
                <h3 class="mb-4">
                    Estado:
                    <strong :class="`${factura.accion_aceptacion_expresa_json.IsValid === 'false' ? 'error' : 'primary'}--text`">
                        {{ factura.accion_aceptacion_expresa_desc }}
                    </strong>
                </h3>

                <p class="mb-4" v-if="factura.accion_aceptacion_expresa_date">
                    <strong class="primary--text"> Enviado y notificado: </strong>
                    {{ factura.accion_aceptacion_expresa_date }}
                </p>

                <p class="mb-4">{{ factura.accion_aceptacion_expresa_json.StatusDescription }}</p>

                <v-expansion-panels accordion>
                    <v-expansion-panel v-for="(err, i) in factura.accion_aceptacion_expresa_json.errores" :key="i">
                        <v-expansion-panel-header disable-icon-rotate>
                            {{ err.split(',')[0] }}
                            <template v-slot:actions>
                                <v-icon color="error"> mdi-alert-circle </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{ err.split(',')[1] }}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-btn v-if="factura.accion_aceptacion_expresa === 'AUTORIZADO'" color="primary" depressed class="mt-4" @click="renviarCorreo('033')"> Enviar copia email </v-btn>
            </v-stepper-content>

            <v-stepper-content step="4" style="height: 300px; overflow-y: scroll">
                <h3 class="mb-4">
                    Estado:
                    <strong :class="`${factura.acccion_reclamo_json.IsValid === 'false' ? 'error' : 'primary'}--text`">
                        {{ factura.acccion_reclamo_desc }}
                    </strong>
                </h3>

                <p class="mb-4">
                    <strong class="primary--text"> Código y concepto del reclamo: </strong>
                    {{
                        factura.acccion_reclamo_json_envio.observacion
                            ? `${factura.acccion_reclamo_json_envio.observacion.id} - ${factura.acccion_reclamo_json_envio.observacion.descripcion}`
                            : 'Código y concepto no disponibles'
                    }}
                </p>

                <p class="mb-4" v-if="factura.acccion_reclamo_date">
                    <strong class="primary--text"> Enviado y notificado: </strong>
                    {{ factura.acccion_reclamo_date }}
                </p>

                <p class="mb-4">{{ factura.acccion_reclamo_json.StatusDescription }}</p>

                <v-expansion-panels accordion>
                    <v-expansion-panel v-for="(err, i) in factura.acccion_reclamo_json.errores" :key="i">
                        <v-expansion-panel-header disable-icon-rotate>
                            {{ err.split(',')[0] }}
                            <template v-slot:actions>
                                <v-icon color="error"> mdi-alert-circle </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{ err.split(',')[1] }}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-btn v-if="factura.acccion_reclamo === 'AUTORIZADO'" color="primary" depressed class="mt-4" @click="renviarCorreo('033')"> Enviar copia email </v-btn>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>
<script>
import { POST } from '@/boot/http'
import eventBus from '@/boot/eventBus'
import { mapGetters } from 'vuex'
export default {
    name: 'EventosElectronicos',
    props: { factura: { type: Object, default: () => ({}) } },
    // mounted() {
    //     console.log(this.factura)
    // },
    data: () => ({
        e1: 1
    }),
    methods: {
        verificarEstadoFactura(accion) {
            return !(
                ((this.factura.acccion_reclamo === 'PENDIENTE' || this.factura.acccion_reclamo === 'AUTORIZADO') && this.factura[accion] === 'DEFAULT') ||
                this.factura[accion] === 'RECHAZADO'
            )
        },
        renviarCorreo(codigo) {
            POST({
                url: 'recepciondoc/reenviaremail',
                data: {
                    p_datajson: {
                        recepciondoc_id: this.factura.id,
                        responsecode: codigo,
                        establecimientocomercio_id: this.getUltimoEstablecimiento
                    },
                    p_opc: 'GET_RECEPCIONDOC_ID',
                    p_auditoriajson: {
                        usuario_id: this.getUserData.id
                    }
                }
            })
                .then(data => {
                    // this.loadingDataTable = false
                    // console.log(data);
                    eventBus.$emit('modal', {
                        value: true,
                        title: 'Envío de documento',
                        text: data.data.status.mensaje,
                        color: data.data.status.status === 'ok' ? 'primary' : 'error'
                    })
                    // this.consultarFacturas()
                })
                .catch(error => {
                    // this.loadingDataTable = false
                    eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
                })
        }
    },
    computed: {
        ...mapGetters(['getUltimoEstablecimiento', 'getUserData'])
    }
}
</script>
