<template>
    <v-container>
        <span v-for="(notificacion, i) in getAlertas" :key="i">
            <alert class="mb-6" v-if="notificacion.visible" :data="{ ...notificacion, dark: true, dismissible: true }" />
        </span>
        <v-row justify="space-between">
            <v-col cols="12" md="4" lg="4">
                <v-autocomplete
                    dense
                    outlined
                    :loading="settingUltimoTercero"
                    :disabled="settingUltimoTercero"
                    @change="changeUltimoTercero"
                    :items="getTerceros"
                    item-text="razon_social"
                    @blur="loseFocusTercero($event)"
                    return-object
                    v-model="currentTercero"
                    label="Tercero"
                >
                    <template v-slot:selection="{ item }">
                        <div v-if="item.tipo_documento_id == '13'">{{ item.nombre1 }} {{ item.apellido1 }}</div>
                        <div v-else-if="item.tipo_documento_id == '31'">{{ item.razon_social }}</div>
                    </template>
                    <template v-slot:item="{ item }">
                        <div v-if="item.tipo_documento_id == '13'">{{ item.nombre1 }} {{ item.apellido1 }}</div>
                        <div v-else-if="item.tipo_documento_id == '31'">{{ item.razon_social }}</div>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" lg="3">
                <v-autocomplete
                    dense
                    outlined
                    item-text="descripcion"
                    item-value="id"
                    @blur="loseFocusEstCo($event)"
                    @change="changeUltimoEstablecimiento"
                    :loading="settingUltimoEstablecimiento"
                    :disabled="settingUltimoEstablecimiento"
                    :items="establecimientos"
                    return-object
                    v-model="currentEstablecimientoComercio"
                    label="Establecimiento de comercio"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="5" lg="5">
                <v-file-input
                    ref="inputFile"
                    label="Cargar documento (ZIP)"
                    outlined
                    dense
                    show-size
                    counter
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon=""
                    @click:clear="$refs.inputFile.errorMessages.pop()"
                    @change="onLoadedData"
                    :disabled="loadingFile"
                ></v-file-input>
                <!-- :loading="loadingFile" -->
            </v-col>
        </v-row>

        <v-card elevation="0" outlined class="pa-6 mb-8">
            <h3 class="mb-6">Consultar facturas</h3>
            <v-form ref="recepcion_documento_form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12" md="6" lg="6">
                        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="date1" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="date1"
                                    label="Fecha inicio"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    :disabled="loadingFile"
                                    :rules="[rules.required, () => dateCheck(date1, date2)]"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date1" @input="$refs.menu1.save(date1), (menu1 = false)" locale="es-co" no-title scrollable />
                        </v-menu>
                    </v-col>

                    <v-col cols="12" md="6" lg="6">
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="date2" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="date2"
                                    label="Fecha final"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    :rules="[rules.required, () => dateCheck(date1, date2)]"
                                    :disabled="loadingFile"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date2" @input="$refs.menu2.save(date2), (menu2 = false)" locale="es-co" no-title scrollable />
                        </v-menu>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6" lg="6">
                        <v-text-field outlined dense :disabled="loadingFile" v-model="numero_factura" label="Número fectura" prepend-inner-icon="mdi-counter"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" lg="6">
                        <v-text-field outlined dense :disabled="loadingFile" v-model="nit_tercero" label="Nit tercero" prepend-inner-icon="mdi-advertisements"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                        <v-select
                            outlined
                            dense
                            :disabled="loadingFile"
                            v-model="filtro_evento"
                            item-value="id"
                            item-text="label"
                            :items="eventos"
                            label="Evento"
                            prepend-inner-icon="mdi-animation"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="6" lg="6">
                        <v-select
                            outlined
                            dense
                            :disabled="loadingFile || filtro_evento === '000'"
                            v-model="filtro_estado_evento"
                            item-value="id"
                            item-text="label"
                            :items="estadosEventos"
                            label="Estado evento"
                            prepend-inner-icon="mdi-state-machine"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row justify="end">
                    <v-col cols="12" md="2" lg="2">
                        <v-btn
                            depressed
                            color="primary"
                            :disabled="loadingFile"
                            :block="$vuetify.breakpoint.xs"
                            @click="consultarFacturas({ ...pagination, page: 1, pageCount: 1 })"
                        >
                            Consultar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>

        <v-row>
            <v-col cols="12">
                <div :class="`${$vuetify.breakpoint.xs ? 'd-block' : 'd-flex'} mx-4`">
                    <h3 class="mb-4 mt-2 mr-auto">Mis facturas</h3>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-btn depressed color="primary" @click="getExcelReport" :disabled="loadingFile || facturas.length === 0">
                                    <!-- :small="$vuetify.breakpoint.xs" -->
                                    {{ $vuetify.breakpoint.xs ? 'Exportar' : 'Exportar excel' }}
                                    <v-icon color="success" class="ml-2">mdi-microsoft-excel</v-icon>
                                </v-btn>
                            </span>
                        </template>
                        <span>Exportar excel</span>
                    </v-tooltip>
                </div>
                <v-data-table
                    dense
                    :headers="headers"
                    :items="facturas"
                    item-key="id"
                    :loading="loadingDataTable"
                    :items-per-page="10"
                    no-data-text="No hay facturas para mostrar"
                    hide-default-footer
                    loading-text="Cargando facturas..."
                    class="elevation-0"
                >
                    <template v-slot:[`item.actions`]="{ index }">
                        {{ facturas_data[index].id }}
                        <!-- {{ hasPermission(getPermisos, 'btn-accion-recibido') }} -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-icon
                                        v-if="!estadosFacturas[index].check_icon"
                                        :disabled="!estadosFacturas[index].accion_recivido"
                                        color="blue"
                                        @click="confirmEvent({ id: '030', factura_id: facturas_data[index].id, descripcion: 'recibido factura' })"
                                    >
                                        <!-- && hasPermission(getPermisos, 'btn-accion-recibido') -->
                                        mdi-text-box-check
                                    </v-icon>
                                </span>
                            </template>
                            <span>Recibido Factura</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-icon
                                        v-if="!estadosFacturas[index].check_icon"
                                        color="blue"
                                        :disabled="!estadosFacturas[index].accion_recivido_bien_servicio"
                                        @click="
                                            confirmEvent({
                                                id: '032',
                                                factura_id: facturas_data[index].id,
                                                descripcion: 'recibido bien y/o servicio'
                                            })
                                        "
                                    >
                                        <!-- && hasPermission(getPermisos, 'btn-accion-recibido-bien-servicio') -->
                                        mdi-basket-check
                                    </v-icon>
                                </span>
                            </template>
                            <span>Recibo del bien o servicio</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-icon
                                        v-if="!estadosFacturas[index].check_icon"
                                        color="blue"
                                        :disabled="!estadosFacturas[index].accion_aceptacion_expresa"
                                        @click="
                                            confirmEvent({
                                                id: '033',
                                                factura_id: facturas_data[index].id,
                                                descripcion: 'aceptación expresa'
                                            })
                                        "
                                    >
                                        <!-- && hasPermission(getPermisos, 'btn-accion-aceptacion-expresa') -->
                                        mdi-file-sign
                                    </v-icon>
                                </span>
                            </template>
                            <span>Aceptación expresa</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-icon
                                        v-if="!estadosFacturas[index].check_icon"
                                        :disabled="!estadosFacturas[index].accion_reclamo"
                                        color="error"
                                        @click=";(facturaReclamoId = facturas_data[index].id), (reclamoModal = true)"
                                    >
                                        <!-- && hasPermission(getPermisos, 'btn-accion-reclamo') -->
                                        mdi-text-box-remove
                                    </v-icon>
                                </span>
                            </template>
                            <span>Reclamo Factura</span>
                        </v-tooltip>
                        <!-- {{ estadosFacturas[index].check_icon }} -->
                        <v-tooltip bottom v-if="estadosFacturas[index].check_icon">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="success" v-bind="attrs" v-on="on"> mdi-check </v-icon>
                            </template>
                            <span>Acuses completados</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="estadosFacturas[index].alert_icon">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="error" v-bind="attrs" v-on="on"> mdi-alert-circle-outline </v-icon>
                            </template>
                            <span>La factura presenta errores</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="estadosFacturas[index].details">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="success" class="ml-1" v-bind="attrs" v-on="on" @click="showDetails(facturas_data[index], facturas[index])"> mdi-eye </v-icon>
                            </template>
                            <span>Ver detalles</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="warning" class="ml-1" v-bind="attrs" v-on="on" @click="abrirFacturaDIAN(facturas[index].cufe)"> mdi-network-pos </v-icon>
                            </template>
                            <span>Ver factura DIAN</span>
                        </v-tooltip>
                    </template>
                    <!-- <template v-slot:footer>
            <v-data-footer
              :pagination.sync="pagination"
              :options="{
                page: pagination.page,
                itemsPerPage: pagination.itemsPerPage
              }"
              show-current-page
              @update:options="consultarFacturas"
              disable-items-per-page
              items-per-page-text="Registros por página"
            />
          </template> -->
                </v-data-table>
                <v-pagination class="mt-2" :total-visible="7" v-model="pagination.page" :length="pagination.itemsLength" @input="consultarFacturas"></v-pagination>
            </v-col>
        </v-row>
        <DetalleFactura :dialog="dialog" @closeDialog="closeDetalleFactura" :factura="facturaDetalleActual" />
        <observacionModal :value="reclamoModal" :facturaId="facturaReclamoId" :rechazoConceptos="rechazoConceptos" @observacion-modal="enviarReclamo" />
        <modalConfirmacion :data="dataConfirmation" @close-confirm="confirmation" />
    </v-container>
</template>
<script>
import DetalleFactura from './detalleFactura'
import { mapGetters, mapMutations } from 'vuex'
import { POST, POST_NOTIFICATIONS } from '@/boot/http'
import eventBus from '@/boot/eventBus'
import observacionModal from '@/components/observacionReclamo.vue'
import { getFirstDayOfMonth } from '@/utils/date'
import { currencyNumer } from '@/utils/currency'
import modalConfirmacion from '@/components/modalConfirmacion.vue'
import { hasPermission } from '@/utils/security'
import { dateNotification } from '@/utils/date'
import { getFileMetaData, onUploadProgress } from '@/utils/other'
import alert from '@/components/alert.vue'

const firstDay = () => {
    // const date = new Date();
    // date.getFullYear(),
    // date.getMonth(),
    return getFirstDayOfMonth()
}

export default {
    name: 'RecepcionDocumento',
    data: () => ({
        dataConfirmation: {},
        rules: {
            required: value => !!value || 'Requerido'
        },
        eventos: [
            { id: '000', label: 'Todos' },
            { id: '030', label: 'Recibido factura' },
            { id: '032', label: 'Recibido bien y/o servicio' },
            { id: '033', label: 'Aceptación expresa' },
            { id: '031', label: 'Acción reclamo' }
        ],
        estadosEventos: [
            { id: 'DEFAULT', label: 'Sin iniciar proceso' },
            { id: 'AUTORIZADO', label: 'Autorizado' },
            { id: 'RECHAZADO', label: 'Rechazado' }
        ],
        filtro_evento: '000',
        filtro_estado_evento: 'DEFAULT',
        establecimientos: [],
        settingUltimoTercero: false,
        settingUltimoEstablecimiento: false,
        valid: true,
        reclamoModal: false,
        facturaReclamoId: null,
        rechazoConceptos: [],
        // prefijo: '',
        numero_factura: '',
        nit_tercero: '',
        loadingFile: false,
        loadingDataTable: false,
        facturaDetalleActual: {},
        date1: firstDay(),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        menu1: false,
        menu2: false,
        dialog: false,
        currentEstablecimientoComercio: null,
        currentTercero: null,
        facturas_data: [],
        estadosFacturas: [],
        facturas: [],
        headers: [
            {
                text: 'Tipo documento',
                align: 'left',
                sortable: true,
                value: 'InvoiceTypeCode',
                width: '1'
            },
            { text: 'Número', value: 'numero_documento', align: 'left' },
            { text: 'Fecha', value: 'fecha', align: 'left' },
            { text: 'Vencimiento', value: 'vencimiento', align: 'left' },
            { text: 'NIT Tercero', value: 'datosempresa.IdDocumentoEmp', align: 'left' },
            { text: 'Nombre Tercero', value: 'datosempresa.rasonsocialEmp', align: 'left' },
            { text: 'Valor', value: 'valor', align: 'left', width: '1' },
            { text: 'Eventos disponibles', value: 'actions', align: 'left' }
        ],
        terceros: [],
        pagination: { page: 1, itemsPerPage: 10, itemsLength: 0, pageCount: 1, pageStop: 0, pageStart: 1 }
    }),
    mounted() {
        // this.getAllTerceros()
        if (this.getEstablecimientosComercio.length > 0) {
            this.currentEstablecimientoComercio = this.getEstablecimientosComercio.find(ec => ec.id === this.getUltimoEstablecimiento)
        }
        if (this.getTerceros.length > 0) {
            this.currentTercero = this.getTerceros.find(t => t.id === this.getUltimoTercero)
        }
        this.establecimientos = this.getEstablecimientosComercio.filter(e => e.id_tercero === this.getUltimoTercero)
        // this.consultarFacturas()
        this.getNotificacionesIndividuales(this.getUserData.id)
        this.getAlertasAPI(this.getUserData.id)
    },
    methods: {
        hasPermission,
        getNotificacionesIndividuales(usuario_id) {
            POST_NOTIFICATIONS({
                url: 'notificaciones/query',
                data: {
                    p_datajson: {
                        usuario_id
                    },
                    p_opc: 'GET_ALL_USER',
                    p_auditoriajson: { usuario_id }
                }
            })
                .then(response => {
                    const data = response.data
                    if (data.status.status === 'ok') {
                        this.setNotificacionesIndividuales({ notificaciones: data.dato })
                    } else {
                        eventBus.$emit('modal', { value: true, title: 'Error', text: data.status.mensaje, color: 'error' })
                    }
                })
                .catch(error => {
                    eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
                })
        },
        getAlertasAPI(usuario_id) {
            POST_NOTIFICATIONS({
                url: 'alertas/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL',
                    p_auditoriajson: { usuario_id }
                }
            })
                .then(response => {
                    const data = response.data
                    if (data.status.status === 'ok') {
                        this.setAlertas({ alertas: data.dato })
                    } else {
                        eventBus.$emit('modal', { value: true, title: 'Error', text: data.status.mensaje, color: 'error' })
                    }
                })
                .catch(error => {
                    eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
                })
        },
        abrirFacturaDIAN(cufe) {
            const url = `https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=${cufe}`
            window.open(url, '_blank').focus()
        },
        dateCheck(date1, date2) {
            var d1 = new Date(date1)
            var d2 = new Date(date2)
            return d1.getTime() <= d2.getTime() || 'La fecha inicial debe ser menor a la fecha final'
        },
        getExcelReport() {
            POST({
                url: 'recepciondoc/query/export',
                data: {
                    p_datajson: {
                        fecha_inicio: this.date1,
                        fecha_final: this.date2,
                        numero_documento: this.numero_factura,
                        filtro_evento: this.filtro_evento,
                        filtro_estado_evento: this.filtro_estado_evento,
                        nit_tercero: this.nit_tercero,
                        page: this.pagination.page,
                        limit: this.pagination.itemsPerPage,
                        establecimientocomercio_id: this.currentEstablecimientoComercio.id
                    },
                    p_opc: 'FILTER_EXPORT',
                    p_auditoriajson: {
                        usuario_id: this.getUserData.id
                    }
                }
            })
                .then(response => {
                    // console.log(response)
                    // eventBus.$emit('progressBar', { visible: false })
                    // this.loadingFile = false
                    // this.$refs.inputFile.reset()
                    // const title = response.data.dato.Isvalid ? response.data.dato.Isvalid.toLowerCase() === 'false' ? 'Error!' : 'Envío de documento' : 'Envío de documento'
                    // const mensajeAccion = response.data.dato.Isvalid ? response.data.dato.Isvalid.toLowerCase() === 'false' ? 'Estado acción: ' + response.data.dato.estado_accion : 'Documento autorizado' : 'Documento enviado'
                    if (response.data.status.status === 'ok') {
                        const { fileData, ext } = getFileMetaData(response.data.dato)
                        var a = document.createElement('a')
                        a.href = fileData
                        a.download = `report-facturas-${new Date().getTime()}.${ext}`
                        a.click()
                    } else {
                        eventBus.$emit('modal', {
                            value: true,
                            title: 'Error',
                            text: response.data.status.mensaje || response.data.mensaje,
                            color: 'error'
                        })
                    }
                })
                .catch(error => {
                    eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
                })
        },
        // openDetails (index) {
        //   this.facturaDetalleActual = JSON.parse(JSON.stringify(this.facturas_data[index]))
        //   this.dialog = true
        // },
        closeDetalleFactura(value) {
            this.dialog = value
        },
        onLoadedData(data) {
            if (data) {
                const file = data
                // console.log(file);
                console.log(file.type)
                if (file.type === 'application/x-zip-compressed' || file.type === 'application/zip') {
                    const reader = new FileReader()
                    reader.onloadend = () => {
                        this.sendFile(reader.result.split(',')[1])
                    }
                    reader.readAsDataURL(file)
                } else {
                    this.$refs.inputFile.errorMessages.push('El archivo no es un archivo zip')
                }
            }
        },
        sendFile(file) {
            this.loadingFile = true
            // console.log({ p_datajson: { file, establecimientocomercio_id: this.currentEstablecimientoComercio.id }, p_opc: 'UPLOADFILE', p_auditoriajson: { usuario_id: this.getUserData.id } })
            POST({
                url: '/recepciondoc/uploadfile',
                data: {
                    p_datajson: {
                        file,
                        establecimientocomercio_id: this.currentEstablecimientoComercio.id
                    },
                    p_opc: 'UPLOADFILE',
                    p_auditoriajson: { usuario_id: this.getUserData.id }
                },
                onUploadProgress
            })
                .then(response => {
                    console.log(response)
                    eventBus.$emit('progressBar', { visible: false })
                    this.loadingFile = false
                    this.$refs.inputFile.reset()
                    const title = response.data.dato.Isvalid ? (response.data.dato.Isvalid.toLowerCase() === 'false' ? 'Error!' : 'Envío de documento') : 'Envío de documento'
                    const mensajeAccion = response.data.dato.Isvalid
                        ? response.data.dato.Isvalid.toLowerCase() === 'false'
                            ? 'Estado acción: ' + response.data.dato.estado_accion
                            : 'Documento autorizado'
                        : 'Documento enviado'
                    if (response.data.status.status === 'ok') {
                        this.date1 = response.data.dato.fecha
                        eventBus.$emit('modal', {
                            value: true,
                            title,
                            text: response.data.status.mensaje || mensajeAccion,
                            color: response.data.dato.Isvalid ? (response.data.dato.Isvalid.toLowerCase() === 'false' ? 'error' : 'primary') : 'primary'
                        })
                        this.consultarFacturas()
                    } else {
                        eventBus.$emit('progressBar', { visible: false })
                        eventBus.$emit('modal', {
                            value: true,
                            title: 'Error',
                            text: response.data.status.mensaje || response.data.mensaje,
                            color: 'error'
                        })
                    }
                })
                .catch(error => {
                    eventBus.$emit('progressBar', { visible: false })
                    this.loadingFile = false
                    // console.log(error)
                    eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
                })
        },
        consultarFacturas(pagination) {
            // console.log(pagination);
            if (this.currentEstablecimientoComercio) {
                const valid = this.$refs.recepcion_documento_form.validate()
                if (valid) {
                    pagination ? (this.pagination = { ...this.pagination, ...pagination }) : null
                    this.loadingDataTable = true
                    POST({
                        url: 'recepciondoc/query',
                        data: {
                            p_datajson: {
                                fecha_inicio: this.date1,
                                fecha_final: this.date2,
                                numero_documento: this.numero_factura,
                                filtro_evento: this.filtro_evento,
                                filtro_estado_evento: this.filtro_estado_evento,
                                nit_tercero: this.nit_tercero,
                                page: this.pagination.page,
                                limit: this.pagination.itemsPerPage,
                                establecimientocomercio_id: this.currentEstablecimientoComercio.id
                            },
                            p_opc: 'FILTER',
                            p_auditoriajson: {
                                usuario_id: this.getUserData.id
                            }
                        }
                    })
                        .then(data => {
                            console.log(data.data.dato.content)
                            if (data.data.status.status === 'ok') {
                                this.facturas_data = data.data.dato.content
                                this.rechazoConceptos = data.data.dato.rechazoconcepto
                                this.facturas = data.data.dato.content.map(obj => ({
                                    ...obj.datadocumento,
                                    valor: currencyNumer(parseFloat(obj.datadocumento.valor))
                                }))
                                // console.log(this.facturas);
                                this.pagination = {
                                    page: data.data.dato.number,
                                    itemsPerPage: data.data.dato.size,
                                    itemsLength: data.data.dato.totalPages,
                                    pageCount: data.data.dato.number,
                                    pageStop: data.data.dato.totalPages,
                                    pageStart: 0
                                }
                                this.asignarEstadosFacturas(data.data.dato.content)
                            } else {
                                this.facturas = []
                                this.facturas_data = []
                                eventBus.$emit('modal', {
                                    value: true,
                                    title: 'Sin registros',
                                    text: data.data.status.mensaje,
                                    color: 'primary'
                                })
                            }
                            this.loadingDataTable = false
                        })
                        .catch(error => {
                            this.loadingDataTable = false
                            eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
                            // console.log(error)
                        })
                } else {
                    eventBus.$emit('modal', {
                        value: true,
                        title: 'Error',
                        text: 'El tercero no posee ningún establecimiento de comercio',
                        color: 'error'
                    })
                }
            }
        },
        enviarReclamo({ factura_id, value, observacion }) {
            this.reclamoModal = value
            this.facturaReclamoId = null
            if (factura_id) {
                this.accionFactura('031', factura_id, observacion)
            }
        },
        confirmEvent(item) {
            this.dataConfirmation = {
                dataId: { id: item.id, factura_id: item.factura_id },
                value: true,
                title: 'Ejecutar la acción!',
                text: `Desea realizar ${item.descripcion}?`,
                color: 'error'
            }
        },
        confirmation(data) {
            if (data.remove) {
                this.accionFactura(data.dataId.id, data.dataId.factura_id)
            }
            this.dataConfirmation = {}
        },
        accionFactura(code, id, observacion = '') {
            this.loadingDataTable = true
            console.log({
                p_datajson: {
                    recepciondoc_id: id,
                    responsecode: code,
                    establecimientocomercio_id: this.currentEstablecimientoComercio.id,
                    observacion
                }
            })
            POST({
                url: 'recepciondoc/notificacion',
                data: {
                    p_datajson: {
                        recepciondoc_id: id,
                        responsecode: code,
                        establecimientocomercio_id: this.currentEstablecimientoComercio.id,
                        observacion
                    },
                    p_opc: 'UPDATE',
                    p_auditoriajson: {
                        usuario_id: this.getUserData.id
                    }
                }
            })
                .then(response => {
                    this.loadingDataTable = false
                    console.log(response.data)
                    if (response.data.status.status !== 'err') {
                        eventBus.$emit('modal', {
                            value: true,
                            title: response.data.dato.Isvalid.toLowerCase() === 'false' ? 'Error!' : 'Envío de documento',
                            text: response.data.dato.Isvalid.toLowerCase() === 'false' ? 'Estado acción: ' + response.data.dato.estado_accion : 'Documento autorizado',
                            color: response.data.dato.Isvalid.toLowerCase() === 'false' ? 'error' : 'primary'
                        })
                        this.consultarFacturas()
                    } else {
                        eventBus.$emit('modal', {
                            value: true,
                            title: 'Error!',
                            text: response.data.status.mensaje_exception || response.data.status.mensaje,
                            color: 'error'
                        })
                    }
                })
                .catch(error => {
                    this.loadingDataTable = false
                    eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
                })
        },
        asignarEstadosFacturas(facturas) {
            this.estadosFacturas = []
            facturas.forEach(factura => {
                const estado = {
                    accion_recivido: true,
                    accion_reclamo: true,
                    accion_recivido_bien_servicio: true,
                    accion_aceptacion_expresa: true,
                    alert_icon: true,
                    details: true,
                    check_icon: true
                }
                const recibido = factura.accion_recivido
                const reclamo = factura.acccion_reclamo
                const recibido_bien_servicio = factura.accion_recivido_bien_servicio
                const aceptacion_expresa = factura.accion_aceptacion_expresa
                // if (factura.id === 1549) {
                //     console.log(factura)
                // }
                if (recibido === 'AUTORIZADO') {
                    estado.accion_recivido = false
                }

                if (recibido_bien_servicio === 'AUTORIZADO' || recibido !== 'AUTORIZADO') {
                    estado.accion_recivido_bien_servicio = false
                }
                // || (reclamo === 'AUTORIZADO')
                if (aceptacion_expresa === 'AUTORIZADO' || recibido_bien_servicio !== 'AUTORIZADO' || reclamo === 'AUTORIZADO') {
                    estado.accion_aceptacion_expresa = false
                }

                if (recibido !== 'AUTORIZADO' || recibido_bien_servicio !== 'AUTORIZADO' || aceptacion_expresa === 'AUTORIZADO' || reclamo === 'AUTORIZADO') {
                    estado.accion_reclamo = false
                }

                if ((recibido !== 'RECHAZADO' && recibido_bien_servicio !== 'RECHAZADO') || aceptacion_expresa === 'AUTORIZADO' || reclamo === 'AUTORIZADO') {
                    estado.alert_icon = false
                }

                if (recibido === 'DEFAULT' && recibido_bien_servicio === 'DEFAULT' && aceptacion_expresa === 'DEFAULT' && reclamo === 'DEFAULT') {
                    estado.details = false
                }

                if (aceptacion_expresa !== 'AUTORIZADO' && reclamo !== 'AUTORIZADO') {
                    estado.check_icon = false
                }

                if (factura.id === 1549) {
                    console.log(estado)
                }

                this.estadosFacturas.push(estado)
            })
        },
        showDetails(factura, data) {
            // console.log(factura)
            this.facturaDetalleActual = factura
            if (this.facturaDetalleActual.accion_recivido_json_envio.fecha_noti) {
                const fullDate = `${this.facturaDetalleActual.accion_recivido_json_envio.fecha_noti}T${this.facturaDetalleActual.accion_recivido_json_envio.hora_noti}`
                const timeZoneDate = new Date(fullDate)
                timeZoneDate.setHours(timeZoneDate.getHours() - 5)
                this.facturaDetalleActual.accion_recivido_date = dateNotification(timeZoneDate)
            }

            if (this.facturaDetalleActual.accion_recivido_bien_servicio_json_envio.fecha_noti) {
                const fullDate = `${this.facturaDetalleActual.accion_recivido_bien_servicio_json_envio.fecha_noti}T${this.facturaDetalleActual.accion_recivido_bien_servicio_json_envio.hora_noti}`
                const timeZoneDate = new Date(fullDate)
                timeZoneDate.setHours(timeZoneDate.getHours() - 5)
                this.facturaDetalleActual.accion_recivido_bien_servicio_date = dateNotification(timeZoneDate)
            }

            if (this.facturaDetalleActual.accion_aceptacion_expresa_json_envio.fecha_noti) {
                const fullDate = `${this.facturaDetalleActual.accion_aceptacion_expresa_json_envio.fecha_noti}T${this.facturaDetalleActual.accion_aceptacion_expresa_json_envio.hora_noti}`
                const timeZoneDate = new Date(fullDate)
                timeZoneDate.setHours(timeZoneDate.getHours() - 5)
                this.facturaDetalleActual.accion_aceptacion_expresa_date = dateNotification(timeZoneDate)
            }

            if (this.facturaDetalleActual.acccion_reclamo_json_envio.fecha_noti) {
                const fullDate = `${this.facturaDetalleActual.acccion_reclamo_json_envio.fecha_noti}T${this.facturaDetalleActual.acccion_reclamo_json_envio.hora_noti}`
                const timeZoneDate = new Date(fullDate)
                timeZoneDate.setHours(timeZoneDate.getHours() - 5)
                this.facturaDetalleActual.acccion_reclamo_date = dateNotification(timeZoneDate)
                this.facturaDetalleActual.observacion = data.observacion
            }
            this.dialog = true
        },
        loseFocusTercero(e) {
            if (!e.target.value) {
                this.currentTercero = this.getTerceros.find(t => t.id === this.getUltimoTercero)
            }
        },
        changeUltimoTercero(evt) {
            if (evt) {
                this.settingUltimoTercero = true
                POST({
                    url: 'terceros/dao',
                    data: {
                        p_datajson: {
                            tercero_id: evt.id,
                            usuario_id: this.getUserData.id
                        },
                        p_opc: 'ULTIMO_TERCERO',
                        p_auditoriajson: { usuario_id: this.getUserData.id }
                    }
                })
                    .then(data => {
                        if (data.data) {
                            const tercero_id = data.data.tercero_id
                            const est = this.getEstablecimientosComercio.filter(e => e.id_tercero === tercero_id)
                            this.establecimientos = est
                            this.setUltimoTercero(tercero_id)
                            if (est.length > 0) {
                                this.currentEstablecimientoComercio = { ...est[0] }
                                this.setUltimoEstablecimiento(est[0].id)
                                this.changeUltimoEstablecimiento({ id: est[0].id })
                            } else {
                                this.setUltimoEstablecimiento(null)
                            }
                            this.settingUltimoTercero = false
                            // console.log(data.data);
                            // eventBus.$emit('modal', { value: true, title: 'Exito!', text: 'Se ha generado un código de verificación, por favor revise el correo asociado al usuario ingresado', color: 'primary' })
                        } else {
                            eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
                        }
                    })
                    .catch(error => {
                        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
                    })
            }
        },
        loseFocusEstCo(e) {
            // console.log(e);
            if (!e.target.value) {
                // e.target.value = this.currentEstablecimientoComercio.descripcion
                this.currentEstablecimientoComercio = this.getEstablecimientosComercio.find(ec => ec.id === this.getUltimoEstablecimiento)
            }
        },
        changeUltimoEstablecimiento(evt) {
            // console.log(evt, this.currentEstablecimientoComercio);
            if (evt) {
                this.settingUltimoEstablecimiento = true
                POST({
                    url: 'establecimientos_comerciales/dao',
                    data: {
                        p_datajson: {
                            establecimientocomercio_id: evt.id,
                            usuario_id: this.getUserData.id
                        },
                        p_opc: 'ULTIMO_ESTABLECIMIENTO',
                        p_auditoriajson: { usuario_id: this.getUserData.id }
                    }
                })
                    .then(data => {
                        if (data.data) {
                            // console.log(data.data);
                            // this.setUltimoEstablecimiento(data.data.establecimientocomercio_id)
                            this.settingUltimoEstablecimiento = false
                            // eventBus.$emit('modal', { value: true, title: 'Exito!', text: 'Se ha generado un código de verificación, por favor revise el correo asociado al usuario ingresado', color: 'primary' })
                        } else {
                            eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
                        }
                    })
                    .catch(error => {
                        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
                    })
            }
        },
        ...mapMutations(['setUltimoTercero', 'setUltimoEstablecimiento', 'setNotificacionesIndividuales', 'setAlertas'])
    },
    computed: {
        ...mapGetters(['getUserData', 'getEstablecimientosComercio', 'getUltimoEstablecimiento', 'getTerceros', 'getUltimoTercero', 'getPermisos', 'getAlertas'])
    },
    components: { DetalleFactura, observacionModal, modalConfirmacion, alert }
}
</script>
