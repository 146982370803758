<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="ml-1 mb-4 mt-4">Editar perfil</h3>
      </v-col>
    </v-row>
    <v-form
      ref="perfilForm"
      v-model="valid"
      lazy-validation
    >
      <v-row justify="center">
        <v-col cols="12" md="4" lg="4">
          <v-select
            label="Tipo de documento"
            outlined
            dense
            item-text="descripcion"
            item-value="id"
            v-model="usuario.persona.tipo_documento_id"
            :rules="[rules.required]"
            :items="tiposDocumentos"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Número documento / DV"
            outlined
            dense
            type="number"
            @input="setDigitoVerificacion"
            :rules="[rules.required]"
            v-model="usuario.persona.n_documento"
          >
            <template v-slot:append>
              <div v-if="usuario.persona.tipo_documento_id === '31'">
                {{usuario.persona.digitoverificacion}}
              </div>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="E-mail"
            outlined
            dense
            :rules="[rules.required, rules.email]"
            v-model="usuario.persona.email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Primer nombre"
            outlined
            dense
            v-model="usuario.persona.nombre1"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Segundo nombre"
            outlined
            dense
            v-model="usuario.persona.nombre2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Primer apellido"
            outlined
            dense
            v-model="usuario.persona.apellido1"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Segundo apellido"
            outlined
            dense
            v-model="usuario.persona.apellido2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="8" lg="8">
          <v-autocomplete
            label="Municipio"
            outlined
            dense
            item-text="descripcion"
            item-value="id"
            :items="municipios"
            :rules="[rules.required]"
            v-model="usuario.persona.municipio_id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Nombre de usuario"
            outlined
            dense
            :rules="[rules.required]"
            v-model="usuario.username"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-btn
            color="primary"
            depressed
            block
            @click="changePassword = true"
          >
            Cambiar contraseña
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Cargo"
            outlined
            dense
            :rules="[rules.required]"
            v-model="usuario.cargo"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Oficina"
            outlined
            dense
            :rules="[rules.required]"
            v-model="usuario.oficina"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="end" class="mb-2 mr-2">
      <v-btn
        depressed
        dense
        outlined
        color="primary"
        class="mr-2"
        @click="$router.push('/home/recepcion-documentos')"
      >Cancelar</v-btn>
      <v-btn
        depressed dense color="primary"
        @click="crearActualizar"
      >Actualizar</v-btn>
    </v-row>
    <passwordModal :value="changePassword" @closeChangePassword="closeChangePassword" />
  </v-container>
</template>
<script>
import { POST } from '@/boot/http'
import { mapGetters, mapMutations } from 'vuex'
import { getDV } from '@/utils/digitoVerificacion'
// import { randomPassword } from '@/utils/password'
import passwordModal from '@/components/passwordModal.vue'
import eventBus from '@/boot/eventBus'
import jwtDecode from 'jwt-decode'

export default {
  name: 'PerfilIndex',
  components: { passwordModal },
  data: () => ({
    usuario: {
      oficina: '',
      cargo: '',
      persona: {
        email: '',
        nombre1: '',
        nombre2: '',
        apellido1: '',
        apellido2: '',
        n_documento: '',
        municipio_id: '',
        tipo_documento_id: '',
        digitoverificacion: ''
      }
    },
    valid: true,
    changePassword: false,
    rules: {
      required: value => !!value || 'Requerido',
      email:  val => /.+@.+\..+/.test(val) || 'Email no válido'
    },
    municipios: [],
    tiposDocumentos: [],
    establecimientos: [],
    mostrarContrasena: false
  }),
  mounted () {
    this.usuario = JSON.parse(JSON.stringify(this.getUserData))
    // console.log(this.usuario);
    this.getTiposDocumentos()
    this.getMuniciopios()
  },
  methods: {
    closeChangePassword ({ value, data }) {
      this.changePassword = value
      if (data) {
        this.setPassword(data)
      }
    },
    setPassword ({ actual, nueva, confirmacion }) {
      POST({
        url: 'usuarios/cambiarpwd',
        data: {
          p_datajson: {
            actual,
            nueva,
            confirmacion
          },
          p_opc: 'NEW_PWD',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    setDigitoVerificacion (nit) {
      this.usuario.digito_verificacion = getDV(nit)
    },
    crearActualizar () {
      const valid = this.$refs.perfilForm.validate()
      if (valid) {
        POST({
          url: 'usuarios/dao',
          data: {
            p_datajson: this.usuario,
            p_opc: 'UPDATE_PROFILE',
            p_auditoriajson: { usuario_id: this.getUserData.id }
          }
        }).then(data => {
          if (data.data.status.status === 'ok') {
            eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
              const token = localStorage.getItem('token')
              if (token) {
                const decoded = jwtDecode(token)
                this.getUser(decoded)
              }
          } else {
            eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
          }
        }).catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
      }
    },
    getUser (decoded) {
      POST({
        url: 'usuarios/query',
        data: {
          p_datajson: { username: decoded.sub },
          p_opc: 'FIND_BY_USUARIO',
          p_auditoriajson: { usuario_id: 3 }
        }
      }).then(data => {
        this.setUserData(data.data.dato)
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    getMuniciopios() {
      POST({
        url: 'municipios/query',
        data: {
          p_datajson: {},
          p_opc: 'GET_ALL',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          this.municipios = data.data.dato
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    getTiposDocumentos() {
      POST({
        url: 'tipodocumentos/query',
        data: {
          p_datajson: {},
          p_opc: 'GET_ALL',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          this.tiposDocumentos = data.data.dato
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    ...mapMutations(['setUserData'])
  },
  computed: {
    ...mapGetters(['getUserData'])
  }
}
</script>