export default {
  getToken: state => state.token,
  getUserData: state => state.data,
  getTerceros: state => state.terceros,
  getEstablecimientosComercio: state => state.establecimientosComercio,
  getMenu: state => {
    const items = [...state.menu]
    const menu = []
    items.forEach(item => {
      const id = item.id
      const children = items.filter(i => i.hijo === id)
      if (children.length > 0) {
        const newItem = { ...item, children }
        menu.push(newItem)
      } else if (!item.hijo) {
        menu.unshift(item)
      }
    })
    return menu
  },
  getPermisos: state => state.permisos,
  getUltimoEstablecimiento: state => state.ultimoEstablecimiento,
  getUltimoTercero: state => state.ultimoTercero
}
