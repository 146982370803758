var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-6",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","to":"/home/alertas/crear-alerta"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v(" Nuevo ")]):_vm._e(),_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"ml-1 mb-4"},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","dense":"","color":"primary"},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_vm._v(" Alertas ")],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.alertas,"item-key":"id","loading":_vm.loadingDataTable,"no-data-text":"No hay alertas para mostrar","loading-text":"Cargando alertas...","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar registros","outlined":"","dense":""},on:{"input":_vm.onSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.dateNotification(item.expiration_date)))])]}},{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.tipo}},[_vm._v(_vm._s(_vm.getIcon(item.tipo)))])]}},{key:"item.visible",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":("" + (item.visible ? 'success' : 'primary'))}},[_vm._v(" mdi-"+_vm._s(item.visible ? 'check-circle' : 'checkbox-blank-circle-outline')+" ")])]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'editar-alerta', params: { alerta_id: item.id } })}}},[_vm._v(" mdi-account-edit ")]),_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirmRemove(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)}),_c('v-pagination',{staticClass:"mt-2",attrs:{"total-visible":7,"length":_vm.pagination.itemsLength},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1),_c('modalConfirmacion',{attrs:{"data":_vm.dataRemove},on:{"close-confirm":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }