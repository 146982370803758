<template>
  <v-container>
    <v-row class="mb-6" justify="end">
      <v-col cols="2">
        <v-btn
          depressed
          color="primary"
          to="/home/roles/nuevo-rol"
        >
          <span v-if="$vuetify.breakpoint.mdAndUp">
            Nuevo
          </span>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <h3 class="ml-1 mb-4">Roles</h3>
        <v-data-table
          dense
          :headers="headers"
          :items="roles"
          item-key="id"
          :loading="loadingDataTable"
          no-data-text="No hay roles para mostrar"
          hide-default-footer
          loading-text="Cargando roles..."
          class="elevation-0"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-icon
              color="primary"
              class="mr-2"
              @click="$router.push({ name: 'editar-rol', params: { role_id: item.id } })"
            >
              mdi-store-edit
            </v-icon>
            <v-icon
              color="primary"
              @click="confirmDelete(item)"
            >mdi-delete</v-icon>
          </template>
          <template v-slot:top>
            <v-text-field
              v-model="search"
              @input="onSearch"
              label="Buscar registros"
              outlined
              dense
              class="mx-4"
            ></v-text-field>
          </template>
        </v-data-table>
        <v-pagination
          class="mt-2"
          :total-visible="7"
          v-model="pagination.page"
          :length="pagination.itemsLength"
          @input="getRoles($event, search)"
        ></v-pagination>
      </v-col>
    </v-row>
    <modalConfirmacion :data="dataRemove" @close-confirm="close" />
  </v-container>
</template>
<script>
// import eventBus from '@/boot/eventBus'
import { mapGetters } from 'vuex'
// import { POST } from '@/boot/http'
import modalConfirmacion from '@/components/modalConfirmacion.vue'
import { POST } from '@/boot/http'
import eventBus from '@/boot/eventBus'

export default {
  name: 'RolesIndex',
  components: { modalConfirmacion },
  data: () => ({
    loadingDataTable: false,
    dataRemove: {},
    search: '',
    roles: [],
    pagination: { page: 1, limit: 10, itemsLength: 0 },
    headers: [
      { text: 'Descripción', value: 'descripcion', align: 'center' },
      { text: 'Acciones', value: 'acciones', align: 'center' }
    ]
  }),
  mounted () {
    this.getRoles()
  },
  methods: {
    onSearch () {
      if (this.search) {
        this.getRoles(1, this.search)
      } else {
        this.getRoles(1, '')
      }
    },
    confirmDelete (item) {
      this.dataRemove = {
        dataId: item.id,
        value: true,
        title: 'Estás a punto de eliminar!',
        text: `Estás seguro que deseas eliminar el rol ${item.descripcion}?`,
        color: 'error'
      }
    },
    close(data) {
      if (data.remove) {
        this.deleteRol(data.dataId)
      }
      this.dataRemove = {}
    },
    getRoles (page = 1, term = '') {
      this.loadingDataTable = true
      POST({
        url: 'roles/query',
        data: {
          p_datajson: {
            page,
            term,
            limit: 10
          },
          p_opc: 'FILTER',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(response => {
        if (response.data.status.status === 'ok') {
          const data = response.data.dato
          // console.log(data);
          this.roles = data.content
          this.pagination = { page: data.number, limit: data.size, itemsLength: data.totalPages }
        } else if (response.data.status.status === 'err') {
          eventBus.$emit('modal', { value: true, title: 'Error', text: response.data.status.mensaje, color: 'error' })
        } else {
          this.roles = []
        }
        this.loadingDataTable = false
      }).catch(error => {
        this.loadingDataTable = false
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    deleteRol(id) {
      POST({
        url: 'roles/dao',
        data: {
          p_datajson: { id },
          p_opc: 'DELETE',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
          this.getRoles()
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    // ...mapMutations(['removeEstablecimientoComercio'])
  },
  computed: {
    ...mapGetters(['getUserData'])
  }
}
</script>