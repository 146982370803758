<template>
  <v-dialog
    transition="dialog-top-transition"
    persistent
    width="500"
    :value="data.visible"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
        dense
      >Cargando...</v-toolbar>
      <v-card-text class="pt-5 pl-10 pr-10 primary--text">
        <v-progress-linear
          :value="data.value"
          color="secondary"
          buffer-value="0"
          height="30"
          stream
          rounded
        >
          <template v-slot:default="{ value }">
            <strong class="primary--text">{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ProgressBar',
  props: {
    data: { value: { type: Boolean, default: false }, visible: { type: Boolean, default: false } }
  },
  data: () => ({
  })
}
</script>