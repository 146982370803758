var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-6",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","to":"/home/terceros/nuevo-tercero"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v(" Nuevo ")]):_vm._e(),_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"ml-1 mb-4"},[_vm._v("Terceros")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.terceros,"item-key":"id","loading":_vm.loadingDataTable,"no-data-text":"No hay terceros para mostrar","hide-default-footer":"","loading-text":"Cargando terceros..."},scopedSlots:_vm._u([{key:"item.razon_social",fn:function(ref){
var item = ref.item;
return [(item.tipo_documento_id == '13')?_c('div',[_vm._v(_vm._s(item.nombre1)+" "+_vm._s(item.apellido1))]):(item.tipo_documento_id == '31')?_c('div',[_vm._v(_vm._s(item.razon_social))]):_vm._e()]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'configuracion-tercero', params: { tercero_id: item.id } })}}},'v-icon',attrs,false),on),[_vm._v("mdi-cog")])]}}],null,true)},[_c('span',[_vm._v("Configuración")])]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'editar-tercero', params: { tercero_id: item.id } })}}},[_vm._v(" mdi-account-edit ")]),_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirmRemove(item)}}},[_vm._v("mdi-delete")])]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar registros","outlined":"","dense":""},on:{"input":_vm.onSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}],null,true)}),_c('v-pagination',{staticClass:"mt-2",attrs:{"total-visible":7,"length":_vm.pagination.itemsLength},on:{"input":function($event){return _vm.getListTerceros($event, _vm.search)}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1),_c('modalConfirmacion',{attrs:{"data":_vm.dataRemove},on:{"close-confirm":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }