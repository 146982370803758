<template>
  <v-container>
    <v-row justify="space-between" class="px-6 pt-6">
      <h3 class="ml-1">Comparar facturas DIAN vs Software</h3>
      <v-col cols="12">
        <v-alert
          v-for="(error, i) in errors" :key="i"
          border="bottom"
          color="error"
          dark
        >
          {{ error }}
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-file-input
          ref="inputFile"
          label="Cargar documento (excel)"
          outlined
          dense
          show-size
          counter
          prepend-inner-icon="mdi-paperclip"
          prepend-icon=""
          @change="changeFile"
          @click:clear="$refs.inputFile.errorMessages.pop()"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row justify="space-between" class="px-6">
      <v-col cols="12" md="4" lg="4">
        <v-text-field
          outlined
          dense
          disabled
          v-model="data.fecha_inicio"
          label="Fecha inicio"
          prepend-inner-icon="mdi-calendar"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <v-text-field
          outlined
          dense
          disabled
          v-model="data.fecha_final"
          label="Fecha final"
          prepend-inner-icon="mdi-calendar"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <v-text-field
          outlined
          dense
          disabled
          v-model="data.nit_tercero"
          label="Nit tercero"
          prepend-inner-icon="mdi-advertisements"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="px-6 d-flex justify-space-between align-center">
      <h4 class="pl-3">Total registros a comparar: {{ data.array_data.length }}</h4>
      <v-btn
        depressed
        color="primary"
        @click="getCompare"
        :disabled="data.array_data.length === 0"
      >
        Comparar
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import * as XLSX from 'xlsx'
import { mapGetters } from 'vuex'
import { POST } from '@/boot/http'
import eventBus from '@/boot/eventBus'
import { getFileMetaData } from '@/utils/other'
import { diffDates } from '@/utils/date'

const defaultData = () => JSON.parse(JSON.stringify({
  fecha_inicio: '--:--',
  fecha_final: '--:--',
  array_data: [],
  nit_tercero: 'No disponible',
  establecimientocomercio_id: null,
}))

export default {
  name: 'ComparacionDianVsSoftware',
  data: () => ({
    data: defaultData(),
    errors: []
  }),
  mounted () {
    const tercero = this.getTerceros.find(t => t.id === this.getUltimoTercero)
    // console.log(tercero)
    this.data.nit_tercero = tercero.n_documento
  },
  methods: {
    changeFile (file) {
      const reader = new FileReader();

      reader.onloadend = (e) => {
        this.errors = []
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'binary' })
        workbook.SheetNames.forEach(sheet => {
          const XL_ROW_OBJECT = XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
          const tercero = this.getTerceros.find(t => t.id === this.getUltimoTercero)
          // nit actual y nit del receptor deben ser el mismo
          // solo facturas electrónicas
          // grupo sera recibido
          const same_nit = XL_ROW_OBJECT.some(factura => factura['NIT Receptor'] == tercero.n_documento)
          if (!same_nit) {
            this.errors.push('No hay facturas que coinsidan con el nit del tercero actual')
          }

          const same_group = XL_ROW_OBJECT.some(factura => (factura['Grupo'] === 'Recibido') && same_nit)
          if (!same_group) {
            this.errors.push('No hay facturas que pertenezcan al grupo de "Recibido"')
          }

          const same_type = XL_ROW_OBJECT.some(factura => (
              factura['Tipo de documento'].toLowerCase() === 'factura electrónica'
              ||
              factura['Tipo de documento'].toLowerCase() === 'factura electronica'
            )
            && same_nit
          )
          if (!same_type) {
            this.errors.push('No hay facturas que sean de tipo "Facturas electrónicas"')
          }

          if (same_nit && same_group && same_type) {
            const filtered_array = XL_ROW_OBJECT.filter(factura =>
                factura['NIT Receptor'] == tercero.n_documento
                &&
                factura['Grupo'] === 'Recibido'
                &&
                (
                  factura['Tipo de documento'].toLowerCase() === 'factura electrónica'
                  ||
                  factura['Tipo de documento'].toLowerCase() === 'factura electronica'
                )
              )

              if (filtered_array.length > 0) {
                const array_fechas = XL_ROW_OBJECT.map(factura => new Date(factura['Fecha Emisión'].split('-').reverse().join('-')))
                var max = new Date(Math.max.apply(null, array_fechas)).toISOString().split('T')[0]
                var min = new Date(Math.min.apply(null, array_fechas)).toISOString().split('T')[0]
                this.data.fecha_inicio = min
                this.data.fecha_final = max
                const formula = (1000 * 60 * 60 * 24) // difference in days
                const diff = diffDates(min, max, formula)
                if (diff <= 30) {
                  this.data.array_data = [...filtered_array]
                  // this.data.nit_tercero = XL_ROW_OBJECT[0]['NIT Receptor']
                } else {
                  this.errors.push('El rango de fechas no debe ser superior a 30 días')
                }
              }
          }

        })
        // console.log(workbook)
      };

      reader.readAsBinaryString(file);
    },
    getCompare () {
      eventBus.$emit('progressBarInfinite', { visible: true })
      POST({
        url: 'recepciondoc/compare',
        data: {
          p_datajson: { ...this.data, establecimientocomercio_id: this.getUltimoEstablecimiento },
          p_opc: 'FILTER_COMPARE',
          p_auditoriajson: {
            usuario_id: this.getUserData.id
          }
        }
      })
      .then(response => {
        if (response.data.status.status === 'ok') {
          const { fileData, ext } = getFileMetaData(response.data.dato)
          var a = document.createElement('a')
          a.href = fileData
          a.download = `report-compare-${new Date().getTime()}.${ext}`
          a.click()
        } else {
          eventBus.$emit('modal', {
            value: true,
            title: response.data.status.status === 'ok' ? 'Error' : 'Advertencia',
            text: response.data.status.mensaje || response.data.mensaje,
            color: response.data.status.status === 'ok' ? 'error' : 'warning'
          })
        }
        eventBus.$emit('progressBarInfinite', { visible: false })
      })
      .catch(error => {
        eventBus.$emit('progressBarInfinite', { visible: false })
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    }
  },
  computed: {
    ...mapGetters([
      'getUltimoTercero',
      'getTerceros',
      'getUltimoEstablecimiento',
      'getUserData',
      'getTerceros'
    ])
  }
}
</script>