export default {
  token: localStorage.getItem('token'),
  data: {},
  terceros: [],
  establecimientosComercio: [],
  menu: [],
  permisos: [],
  ultimoTercero: null,
  ultimoEstablecimiento: null
}
