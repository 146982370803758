<template>
  <v-app id="inspire">
    <app-bar />
    <v-main class="grey lighten-3">
      <progressBar :data="dataProgress" />
      <progressBarInfinite :data="dataProgressInfinite" />
      <modalComponent :data="modalData" />
      <v-container v-if="dataLoaded">
        <transition name="scale-transition">
          <router-view />
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import appBar from '@/components/appBar.vue'
import { POST, POST_NOTIFICATIONS } from '@/boot/http'
import jwtDecode from 'jwt-decode'
import { mapActions, mapMutations } from 'vuex'
import eventBus from '@/boot/eventBus'
import modalComponent from '@/components/modal.vue'
import progressBar from '@/components/progressBar.vue'
import progressBarInfinite from '@/components/progressBarInfinite.vue'

export default {
  name: 'App',
  components: { appBar, modalComponent, progressBar, progressBarInfinite },
  mounted() {
    this.dataLoaded = false
    const token = localStorage.getItem('token')
    if (token) {
      const decoded = jwtDecode(token)
      this.getUserData(decoded)
    } else {
      this.dataLoaded = true
    }
    this.initializeEvents()
  },
  data: () => ({
    dataLoaded: false,
    modalData: {},
    dataProgress: {},
    dataProgressInfinite: {}
  }),
  methods: {
    getNotificacionesIndividuales(usuario_id) {
      POST_NOTIFICATIONS({
        url: 'notificaciones/query',
        data: {
          p_datajson: {
            usuario_id
          },
          p_opc: 'GET_ALL_USER',
          p_auditoriajson: { usuario_id }
        }
      })
        .then(response => {
          const data = response.data
          if (data.status.status === 'ok') {
            this.setNotificacionesIndividuales({ notificaciones: data.dato })
          } else {
            console.log('b')
            eventBus.$emit('modal', { value: true, title: 'Error', text: data.status.mensaje, color: 'error' })
          }
        })
        .catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
    },
    getAlertas(usuario_id) {
      POST_NOTIFICATIONS({
        url: 'alertas/query',
        data: {
          p_datajson: {},
          p_opc: 'GET_ALL',
          p_auditoriajson: { usuario_id }
        }
      })
        .then(response => {
          const data = response.data
          if (data.status.status === 'ok') {
            this.setAlertas({ alertas: data.dato })
          } else {
            eventBus.$emit('modal', { value: true, title: 'Error', text: data.status.mensaje, color: 'error' })
          }
        })
        .catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
    },
    getUserData(decoded) {
      POST({
        url: 'usuarios/query',
        data: {
          p_datajson: { username: decoded.sub },
          p_opc: 'FIND_BY_USUARIO',
          p_auditoriajson: { usuario_id: 3 }
        }
      })
        .then(data => {
          this.dataLoaded = true
          this.setUserData(data.data.dato)
          this.setTerceros(data.data.dato.tercero)
          this.setEstablecimientosComercio(data.data.dato.establecimientocomercio)
          this.getAlertas(data.data.dato.id)
          this.getNotificacionesIndividuales(data.data.dato.id)
          this.socket_connect()
        })
        .catch(error => {
          this.dataLoaded = true
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
    },
    initializeEvents() {
      eventBus.$on('modal', data => {
        this.modalData = data
      })

      eventBus.$on('progressBar', data => {
        this.dataProgress = data
      })

      eventBus.$on('progressBarInfinite', data => {
        this.dataProgressInfinite = data
      })
    },
    logout() {
      localStorage.removeItem('token')
      this.setToken(null)
      this.setUserData({})
      this.setDefaultAlertas([])
      this.setDefaultNotificaciones([])
      this.$router.push('/login')
    },
    ...mapMutations([
      'setUserData',
      'setTerceros',
      'setEstablecimientosComercio',
      'setToken',
      'setUserData',
      'setAlertas',
      'setNotificacionesIndividuales',
      'setDefaultAlertas',
      'setDefaultNotificaciones'
    ]),
    ...mapActions(['socket_connect'])
  },
  watch: {
    isIdle(newValue) {
      if (newValue) {
        this.logout()
      }
    }
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'Titilium';
  src: local('Titilium'), url(./assets/fonts/Titillium_Web/TitilliumWeb-Regular.ttf) format('truetype');
}

#inspire {
  font-family: 'Titilium', Helvetica, Arial;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>