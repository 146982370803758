<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="ml-1 mb-4 mt-4">Nuevo Usuario</h3>
      </v-col>
    </v-row>
    <v-form
      ref="formUsuario"
      v-model="valid"
      lazy-validation
    >
      <v-row justify="center">
        <v-col cols="12" md="4" lg="4">
          <v-select
            label="Tipo de documento"
            outlined
            dense
            item-text="descripcion"
            item-value="id"
            v-model="usuario.persona.tipo_documento_id"
            :rules="[rules.required]"
            :items="tiposDocumentos"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Número documento / DV"
            outlined
            dense
            type="number"
            @input="setDigitoVerificacion"
            :rules="[rules.required]"
            v-model.number="usuario.persona.n_documento"
          >
            <template v-slot:append>
              <div v-if="usuario.persona.tipo_documento_id === '31'">
                {{usuario.digitoverificacion}}
              </div>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="E-mail"
            outlined
            dense
            :rules="[rules.required, rules.email]"
            v-model="usuario.persona.email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Primer nombre"
            outlined
            dense
            v-model="usuario.persona.nombre1"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Segundo nombre"
            outlined
            dense
            v-model="usuario.persona.nombre2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Primer apellido"
            outlined
            dense
            v-model="usuario.persona.apellido1"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="Segundo apellido"
            outlined
            dense
            v-model="usuario.persona.apellido2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="8" lg="8">
          <v-autocomplete
            label="Municipio"
            outlined
            dense
            item-text="descripcion"
            item-value="id"
            :items="municipios"
            :rules="[rules.required]"
            v-model="usuario.persona.municipio_id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <v-autocomplete
            label="Terceros"
            ref="terceros"
            @change="arrayValidation('terceros', $event), changeTercero($event)"
            outlined
            multiple
            dense
            item-text="razon_social"
            item-value="id"
            :items="terceros"
            v-model="usuario.tercero"
          >
            <!-- item-text="razon_social" -->
            <template v-slot:selection="{ item }">
              <v-chip
                small
                v-if="item.tipo_documento_id == '13'"
                color="primary"
              >{{ item.nombre1 }} {{ item.apellido1 }}</v-chip>
              <v-chip
                small
                v-else-if="item.tipo_documento_id == '31'"
                color="primary"  
              >{{ item.razon_social }}</v-chip>
            </template>
            <template v-slot:item="{ item }">
              <!-- <v-checkbox
                v-if="item.tipo_documento_id == '13'"
                color="primary"
                v-model="usuario.tercero"
                :value="item.id"
                readonly
                :label="`${item.nombre1} ${item.apellido1}`"
              ></v-checkbox>
              <v-checkbox
                v-else-if="item.tipo_documento_id == '31'"
                color="primary"
                readonly
                v-model="usuario.tercero"
                :value="item.id"
                :label="item.razon_social"
              ></v-checkbox> -->
              <span v-if="item.tipo_documento_id == '13'">
                <!-- <v-icon v-if="usuario.tercero.indexOf(item.id) !== -1">mdi-text-box-check</v-icon> -->
                {{`${item.nombre1} ${item.apellido1}`}}
              </span>
              <span v-else-if="item.tipo_documento_id == '31'">
                <!-- <v-icon v-if="usuario.tercero.indexOf(item.id) !== -1">mdi-text-box-check</v-icon> -->
                {{ item.razon_social }}
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-autocomplete
            label="Establecimiento comercio"
            ref="estCo"
            outlined
            multiple
            dense
            @change="arrayValidation('estCo', $event)"
            item-text="descripcion"
            item-value="id"
            :disabled="establecimientos.length === 0"
            :items="establecimientos"
            v-model="usuario.establecimientocomercio"
          >
            <template v-slot:selection="{ item }">
              <v-chip
                small
                color="primary"
              >{{ item.descripcion }}</v-chip>
            </template>
            <template v-slot:item="{ item }">
              <!-- <v-checkbox
                color="primary"
                readonly
                v-model="usuario.establecimientocomercio"
                :value="item.id"
                :label="item.descripcion"
              ></v-checkbox> -->
              <span>
                {{ item.descripcion }}
              </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :md="!usuario.id ? '4' : '6'" :lg="!usuario.id ? '4' : '6'">
          <v-text-field
            label="Nombre de usuario"
            outlined
            dense
            :rules="[rules.required]"
            v-model="usuario.username"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4" v-if="!usuario.id">
          <v-text-field
            label="Contraseña"
            outlined
            dense
            :append-icon="mostrarContrasena ? 'mdi-eye-off' : 'mdi-eye'"
            :type="mostrarContrasena ? 'text' : 'password'"
            @click:append="mostrarContrasena = !mostrarContrasena"
            v-model="usuario.password"
            :rules="[rules.required, rules.password]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" :md="!usuario.id ? '4' : '6'" :lg="!usuario.id ? '4' : '6'">
          <v-autocomplete
            label="Rol"
            outlined
            dense
            item-text="descripcion"
            item-value="id"
            :items="roles"
            :rules="[rules.required]"
            v-model="usuario.role_id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Cargo"
            outlined
            dense
            v-model="usuario.cargo"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Oficina"
            outlined
            dense
            v-model="usuario.oficina"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="end" class="mb-2 mr-2">
      <v-btn
        depressed
        dense
        outlined
        color="primary"
        class="mr-2"
        @click="$router.back()"
      >Cancelar</v-btn>
      <v-btn
        v-if="usuario.id"
        depressed
        dense
        color="primary"
        @click="crearActualizar"
      >Actualizar</v-btn>
      <v-btn
        v-else
        depressed
        dense
        color="primary"
        @click="crearActualizar"
      >Crear</v-btn>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, /*mapMutations*/ } from 'vuex'
import { getDV } from '@/utils/digitoVerificacion'
import { POST } from '@/boot/http'
import eventBus from '@/boot/eventBus'
import { randomPassword } from '@/utils/password'

const defaultUsuario = () => JSON.parse(JSON.stringify({
  enabled: true,
  password: randomPassword(),
  username: '',
  role_id: '',
  cargo: '',
  oficina: '',
  persona: {
    n_documento: '',
    tipo_documento_id: '31',
    nombre1: '',
    nombre2: '',
    apellido1: '',
    apellido2: '',
    email: '',
    municipio_id: '',
    digitoverificacion: ''
  },
  tercero: [],
  establecimientocomercio: []
}))

export default {
  name: 'NuevoUsuario',
  data: () => ({
    municipios: [],
    tiposDocumentos: [],
    establecimientos: [],
    allEstablecimientos: [],
    roles: [],
    usuario: defaultUsuario(),
    valid: true,
    terceros: [],
    mostrarContrasena: false,
    rules: {
      required: value => !!value || 'Requerido',
      email:  val => /.+@.+\..+/.test(val) || 'Email no válido',
      password: val => val.length > 7 || 'Minimo 8 caracteres'
    }
  }),
  mounted () {
    this.getMuniciopios()
    this.getTiposDocumentos()
    this.getListTerceros()
    this.getListEstablecimientos()
    this.getRoles()
    if (this.$route.params.usuario) {
      this.usuario = JSON.parse(JSON.stringify(this.$route.params.usuario))
      this.usuario.tercero = this.usuario.tercero ? this.usuario.tercero.map(t => t.id) : []
      this.usuario.establecimientocomercio = this.usuario.establecimientocomercio ? this.usuario.establecimientocomercio.map(e => e.id) : []
    }
  },
  methods: {
    setDigitoVerificacion (nit) {
      this.usuario.digitoverificacion = getDV(nit)
    },
    arrayValidation (ref, array) {
      if (array.length < 1) {
        this.$refs[ref].errorMessages.push('Agregue al menos un elemento')
      } else {
        this.$refs[ref].errorMessages.pop()
      }
    },
    changeTercero(array) {
      const ts = []
      array.forEach(id => {
        const children = this.allEstablecimientos.filter(ec => ec.id_tercero === id)
        ts.push(...children)
      })
      this.establecimientos = ts
    },
    getListTerceros () {
      // console.log({
      //   url: 'terceros/query',
      //   data: {
      //     p_datajson: {},
      //     p_opc: 'GET_ALL',
      //     p_auditoriajson: { usuario_id: this.getUserData.id }
      //   }
      // });
      POST({
        url: 'terceros/query',
        data: {
          p_datajson: {},
          p_opc: 'GET_ALL',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(response => {
        if (response.data.status.status === 'ok') {
          const data = response.data.dato
          this.terceros = data
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: response.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    getListEstablecimientos () {
      POST({
        url: 'establecimientos_comerciales/query',
        data: {
          p_datajson: {},
          p_opc: 'GET_ALL',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(response => {
        if (response.data.status.status === 'ok') {
          const data = response.data.dato
          this.allEstablecimientos = data
          if (this.usuario.establecimientocomercio.length > 0) {
            this.usuario.tercero.forEach(id => {
              const est = this.allEstablecimientos.filter(e => e.id_tercero === id)
              this.establecimientos.push(...est)
            })
          }
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: response.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    crearActualizar() {
      const valid = this.$refs.formUsuario.validate()
      if (valid) {
        this.usuario.tercero = this.usuario.tercero.map(id => ({ id }))
        this.usuario.establecimientocomercio = this.usuario.establecimientos ? this.usuario.establecimientos.map(id => ({ id })) : this.usuario.establecimientocomercio.map(id => ({ id }))
        // console.log(this.usuario);
        const p_opc = this.usuario.id ? 'UPDATE' : 'INSERT'
        POST({
          url: 'usuarios/dao',
          data: {
            p_datajson: this.usuario,
            p_opc,
            p_auditoriajson: { usuario_id: this.getUserData.id }
          }
        }).then(data => {
          if (data.data.status.status === 'ok') {
            eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
            this.usuario = defaultUsuario()
          } else {
            eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
          }
        }).catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
      }
    },
    getRoles() {
      POST({
        url: 'roles/query',
        data: {
          p_datajson: {},
          p_opc: 'GET_ALL',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          this.roles = data.data.dato
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    getMuniciopios() {
      POST({
        url: 'municipios/query',
        data: {
          p_datajson: {},
          p_opc: 'GET_ALL',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          this.municipios = data.data.dato
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    getTiposDocumentos() {
      POST({
        url: 'tipodocumentos/query',
        data: {
          p_datajson: {},
          p_opc: 'GET_ALL',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          this.tiposDocumentos = data.data.dato
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    }
  },
  computed: {
    ...mapGetters(['getTerceros', 'getEstablecimientosComercio', 'getUserData'])
  }
}
</script>